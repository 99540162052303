import React from 'react';
import styled from '@emotion/styled';

type StyledButtonProps = {
  variant: 'default' | 'orange' | 'grey';
  fullWidth: boolean;
  disabled: boolean;
  size: 'medium' | 'small';
};

const StyledButton = styled.button<StyledButtonProps>(
  {
    display: 'flex',
    alignItems: 'center',
    border: 1,
    borderColor: '#12A6A6',
    borderRadius: 28,
    fontFamily: 'Helvetica Neue Bold',
    fontSize: 16,
    lineHeight: 1.2,
    color: '#ffffff',
    minWidth: 200,
    justifyContent: 'center',
    cursor: 'pointer',
    ':active': {
      opacity: 0.8,
    },
  },
  ({ variant, fullWidth, disabled, size }) => {
    let backgroundColor = disabled ? '#797979' : '#12A6A6';
    switch (variant) {
      case 'orange':
        backgroundColor = '#F19334';
        break;
      case 'grey':
        backgroundColor = '#BBBCBC';
        break;
    }
    const width = fullWidth ? '100%' : 'auto';
    const padding = size === 'small' ? '10px 20px' : '17px 30px';
    const minWidth = size === 'small' ? 100 : 200;
    const boxShadow =
      size === 'small' ? '0px 1px 4px #00000034' : '0 6px 10px #00000021';
    return {
      backgroundColor,
      width,
      padding,
      minWidth,
      boxShadow,
    };
  },
);

const Icon = styled.div({
  paddingRight: 5,
});

type ButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit';
  children: React.ReactNode;
  variant?: 'default' | 'orange' | 'grey';
  icon?: JSX.Element;
  fullWidth?: boolean;
  size?: 'medium' | 'small';
};

export default function Button({
  onClick,
  disabled = false,
  type = 'button',
  children,
  variant = 'default',
  fullWidth = false,
  icon,
  size = 'medium',
}: ButtonProps): JSX.Element {
  return (
    <StyledButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      fullWidth={fullWidth}
      size={size}
    >
      {icon ? <Icon>{icon}</Icon> : null}
      {children}
    </StyledButton>
  );
}
