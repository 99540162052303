import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as SvgQuestion } from '../svg/question.svg';
import { ReactComponent as SvgCheck } from '../svg/check.svg';

import Button from './Button';
import { resetPassword } from '../api/request';

type ResetUserPasswordProps = {
  rut: string;
  onRequestClose: () => void;
  showError: (errorTitle: string, errorMessage: string) => void;
  onRequestLogout: () => void;
};

type ResetUserPasswordState = {
  resetting: boolean;
  resetSuccess: boolean;
  userEmail: string;
};

export default class ResetUserPassword extends React.Component<
  ResetUserPasswordProps,
  ResetUserPasswordState
> {
  state: ResetUserPasswordState = {
    resetting: false,
    resetSuccess: false,
    userEmail: '',
  };

  handleReset = async (): Promise<void> => {
    this.setState({
      resetting: true,
    });

    try {
      const rut = this.props.rut;
      const res = await resetPassword(rut);

      if (res.data?.data?.email) {
        this.setState({ userEmail: res.data.data.email });
      }

      this.setState({
        resetSuccess: true,
        resetting: false,
      });
    } catch (error) {
      // Handle not found error in this modal
      // Show global message and close modal for other errors

      if (
        error.response?.data?.message &&
        error.response.data.message.includes("User doesn't have valid email")
      ) {
        this.props.showError(
          'No existe correo asociado',
          'El usuario no tiene un correo válido asociado para enviar la clave provisoria',
        );
        this.props.onRequestClose();
      } else if (error.response?.status === 500) {
        this.props.showError(
          'Error de conexión',
          'Ocurrió un error de conexión. Intenta más tarde.',
        );
        this.props.onRequestClose();
      } else {
        this.props.showError(
          'Error desconocido',
          'Ocurrió un error. Intenta más tarde.',
        );
        this.props.onRequestClose();
      }
      this.setState({
        resetting: false,
      });
    }
  };

  render(): JSX.Element {
    return (
      <Container>
        {this.state.resetSuccess ? (
          <>
            <Header>
              <Icon>
                <SvgCheck width={48} height={48} />
              </Icon>
              <Title>¡Contraseña restablecida con éxito!</Title>
            </Header>
            <Message>
              Le hemos enviado una clave provisoria al correo{' '}
              {this.state.userEmail.toLowerCase()}
            </Message>
            <Buttons>
              <CloseButton>
                <Button onClick={this.props.onRequestClose}>Cerrar</Button>
              </CloseButton>
            </Buttons>
          </>
        ) : (
          <>
            <Header>
              <Icon>
                <SvgQuestion />
              </Icon>
              <Title>
                ¿Estás seguro de restablecer la contraseña para este usuario?
              </Title>
            </Header>
            <Message>Se le enviará un correo con una clave provisoria.</Message>
            <Buttons>
              <ButtonPadding>
                <Button
                  variant="orange"
                  onClick={this.props.onRequestClose}
                  fullWidth
                >
                  Cancelar
                </Button>
              </ButtonPadding>

              <ButtonPadding>
                <Button
                  onClick={this.handleReset}
                  disabled={this.state.resetting}
                  fullWidth
                >
                  {this.state.resetting ? 'Restableciendo...' : 'Restablecer'}
                </Button>
              </ButtonPadding>
            </Buttons>
          </>
        )}
      </Container>
    );
  }
}

const Container = styled.div({
  width: 'calc(100vw - 160px)',
  maxWidth: 511,
});

const Header = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  paddingBottom: 30,
});

const Icon = styled.div({
  paddingRight: 12,
  color: '#E9468C',
});

const Title = styled.div({
  font: 'Medium 30px/13px Helvetica Neue',
  fontFamily: 'Helvetica Neue Medium',
  fontSize: 30,
  lineHeight: 40 / 30,
  letterSpacing: 0,
  color: '#505050',
  opacity: 1,
  textAlign: 'center',
});

const Message = styled.div({
  fontFamily: 'Helvetica Neue Light',
  fontSize: 20,
  lineHeight: 30 / 20,
  textAlign: 'center',
  paddingTop: 0,
  paddingBottom: 65,
});

const Buttons = styled.div({
  paddingVertical: 30,
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'stretch',
  marginLeft: -7,
  marginRight: -7,
});

const ButtonPadding = styled.div({
  width: '100%',
  paddingLeft: 7,
  paddingRight: 7,
});

const CloseButton = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
