import React from 'react';
import styled from '@emotion/styled';
import { Upload } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import axios from 'axios';

import { ReactComponent as SvgAdd } from '../svg/add.svg';
import { ReactComponent as SvgWarning } from '../svg/warning.svg';
import { ReactComponent as SvgUpload } from '../svg/upload.svg';

import Button from './Button';

type User = { username: string; accessToken: string };

type BulkCreateUsersProps = {
  user: User;
  onRequestClose: () => void;
  onLoad: () => void;
  showError: (errorTitle: string, errorMessage: string) => void;
  onRequestLogout: () => void;
};

type BulkCreateUsersState = {
  uploading: boolean;
  fileList: UploadFile[];
  errorMessage: string;
  uploadSuccess: boolean;
};

export default class BulkCreateUsers extends React.Component<
  BulkCreateUsersProps,
  BulkCreateUsersState
> {
  state: BulkCreateUsersState = {
    uploading: false,
    fileList: [],
    errorMessage: '',
    uploadSuccess: false,
  };

  handleChange = (info: UploadChangeParam): void => {
    // Keep last file only
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);
    this.setState({ fileList });
  };

  handleUpload = async (): Promise<void> => {
    const { fileList } = this.state;
    const data = new FormData();
    fileList.forEach((file) => {
      if (file.originFileObj) {
        data.append('file', file.originFileObj);
      }
    });

    this.setState({
      uploading: true,
    });

    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const authToken = this.props.user.accessToken;
      await axios.request({
        method: 'post',
        url: `${baseUrl}/bulk/users`,
        headers: { Authorization: 'Bearer ' + authToken },
        data,
      });
      this.setState({
        fileList: [],
        uploading: false,
      });
      this.props.onLoad();
      this.setState({
        uploadSuccess: true,
        uploading: false,
        errorMessage: '',
      });
      // TODO: save bulk upload id
    } catch (error) {
      let errorMessage = '';
      // Handle bad request errors in this modal
      // Show global message and close modal for other errors
      if (error.response?.status === 400) {
        errorMessage = error.response?.data?.message;
      } else if (error.response?.status === 403) {
        this.props.showError(
          'Sesion expirada',
          'Tu sesión expiró. Por favor ingresa nuevamente.',
        );
        this.props.onRequestLogout();
      } else if (
        error.response?.status === 500 ||
        error.message === 'Network Error'
      ) {
        this.props.showError(
          'Error de conexión',
          'Ocurrió un error de conexión. Intenta más tarde.',
        );
        this.props.onRequestClose();
      } else {
        this.props.showError(
          'Error desconocido',
          'Ocurrió un error. Intenta más tarde.',
        );
        this.props.onRequestClose();
      }
      this.setState({
        uploading: false,
        errorMessage,
      });
    }
  };

  handleTryAgain = (): void => {
    this.setState({
      errorMessage: '',
      fileList: [],
    });
  };

  render(): JSX.Element {
    const baseUrl = process.env.REACT_APP_API_URL;
    return (
      <Container>
        {this.state.uploadSuccess ? (
          <>
            <Header>
              <Icon>
                <SvgUpload width={48} height={48} />
              </Icon>
              <Title>¡Carga en curso!</Title>
            </Header>
            <Message>
              La actualización y creación de usuarios puede demorar unos
              minutos. Los cambios se irán reflejando en el listado de usuario
              al refrescar la página.
            </Message>
            <Buttons>
              <CloseButton>
                <Button onClick={this.props.onRequestClose}>Cerrar</Button>
              </CloseButton>
            </Buttons>
          </>
        ) : this.state.errorMessage === '' ? (
          <>
            <Header>
              <Icon>
                <SvgUpload width={48} height={48} />
              </Icon>
              <Title>Carga masiva de colaboradores</Title>
            </Header>
            <Upload.Dragger
              action={`${baseUrl}/bulk/users`}
              fileList={this.state.fileList}
              onChange={this.handleChange}
              // handle upload manually
              beforeUpload={(): boolean => false}
            >
              <UploadDraggerContent>
                <UploadDraggerIcon>
                  <SvgAdd />
                </UploadDraggerIcon>{' '}
                Selecciona un archivo
              </UploadDraggerContent>
            </Upload.Dragger>
            <Help>
              <p>¿No sabes cómo cargar usuarios? Descarga formato de ejemplo</p>
              <p>Puedes seleccionar máximo 1 archivo*</p>
            </Help>
            <Buttons>
              <ButtonPadding>
                <Button
                  variant="orange"
                  onClick={this.props.onRequestClose}
                  fullWidth
                >
                  Cancelar
                </Button>
              </ButtonPadding>

              <ButtonPadding>
                <Button
                  onClick={this.handleUpload}
                  disabled={
                    this.state.fileList.length === 0 || this.state.uploading
                  }
                  fullWidth
                >
                  {this.state.uploading ? 'Cargando...' : 'Cargar'}
                </Button>
              </ButtonPadding>
            </Buttons>
          </>
        ) : (
          <>
            <Header>
              <Icon>
                <SvgWarning width={48} height={48} />
              </Icon>
              <Title>¡Carga fallida!</Title>
            </Header>
            <Message>
              {this.state.errorMessage}
              <br />
              Corrige tu archivo e inténtalo nuevamente.
            </Message>
            <Buttons>
              <ButtonPadding>
                <Button
                  variant="orange"
                  fullWidth
                  onClick={this.props.onRequestClose}
                >
                  Cancelar
                </Button>
              </ButtonPadding>

              <ButtonPadding>
                <Button fullWidth onClick={this.handleTryAgain}>
                  Cargar nuevamente
                </Button>
              </ButtonPadding>
            </Buttons>
          </>
        )}
      </Container>
    );
  }
}

const Container = styled.div({
  width: 'calc(100vw - 160px)',
  maxWidth: 510,
});

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 30,
});

const Icon = styled.div({
  paddingRight: 12,
  color: '#E9468C',
});

const Title = styled.div({
  font: 'Medium 30px/13px Helvetica Neue',
  fontFamily: 'Helvetica Neue Medium',
  fontSize: '30px',
  letterSpacing: 0,
  color: '#505050',
  opacity: 1,
});

const Help = styled.div({
  fontFamily: 'Helvetica Neue Light',
  fontSize: 14,
  lineHeight: 25 / 14,
  paddingTop: 10,
  paddingBottom: 30,
});

const Message = styled.div({
  fontFamily: 'Helvetica Neue Light',
  fontSize: 20,
  lineHeight: 30 / 20,
  textAlign: 'center',
  paddingTop: 0,
  paddingBottom: 65,
});

const Buttons = styled.div({
  paddingVertical: 30,
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'stretch',
  marginLeft: -7,
  marginRight: -7,
});

const ButtonPadding = styled.div({
  width: '100%',
  paddingLeft: 7,
  paddingRight: 7,
});

const CloseButton = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});

const UploadDraggerContent = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const UploadDraggerIcon = styled.div({
  display: 'flex',
  alignItems: 'center',
  paddingRight: 14,
});
