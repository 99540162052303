import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as SvgQuestion } from '../svg/question.svg';
import { ReactComponent as SvgWarning } from '../svg/warning.svg';
import { ReactComponent as SvgCheck } from '../svg/check.svg';

import Button from './Button';

type SimpleModalProps = {
  iconName: string;
  title: string;
  message: string;
  onRequestClose: () => void;
};

export default class SimpleModal extends React.Component<
  SimpleModalProps,
  any
> {
  renderIcon = (iconName: string): JSX.Element => {
    switch (iconName) {
      case 'question': {
        return <SvgQuestion />;
      }
      case 'warning': {
        return <SvgWarning />;
      }
      case 'check': {
        return <SvgCheck />;
      }
      default: {
        return <div></div>;
      }
    }
  };

  render(): JSX.Element {
    return (
      <Container>
        <Header>
          <Icon>{this.renderIcon(this.props.iconName)}</Icon>
          <Title>{this.props.title}</Title>
        </Header>
        <Message>{this.props.message}</Message>
        <Buttons>
          <CloseButton>
            <Button onClick={this.props.onRequestClose}>Cerrar</Button>
          </CloseButton>
        </Buttons>
      </Container>
    );
  }
}

const Container = styled.div({
  width: 'calc(100vw - 160px)',
  maxWidth: 511,
});

const Header = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'center',
  paddingBottom: 30,
});

const Icon = styled.div({
  paddingRight: 12,
  color: '#E9468C',
});

const Title = styled.div({
  font: 'Medium 30px/13px Helvetica Neue',
  fontFamily: 'Helvetica Neue Medium',
  fontSize: 30,
  lineHeight: 40 / 30,
  letterSpacing: 0,
  color: '#505050',
  opacity: 1,
  textAlign: 'center',
});

const Message = styled.div({
  fontFamily: 'Helvetica Neue Light',
  fontSize: 20,
  lineHeight: 30 / 20,
  textAlign: 'center',
  paddingTop: 0,
  paddingBottom: 65,
});

const Buttons = styled.div({
  paddingVertical: 30,
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'stretch',
  marginLeft: -7,
  marginRight: -7,
});

const CloseButton = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
