import React from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';

import Button from './Button';
import { ReactComponent as SvgExport } from '../svg/export.svg';

async function fetchUsers(authToken: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  try {
    const res = await axios.request({
      method: 'get',
      responseType: 'blob',
      url: `${baseUrl}/admin/users/csv`,
      headers: { Authorization: 'Bearer ' + authToken },
    });
    saveAs(res.data, 'usuarios.csv', { autoBom: true });
  } catch (error) {
    if (error.response?.status === 401) {
      throw new Error('Unauthorized');
    }
    if (error.response?.status === 403) {
      throw new Error('Forbidden');
    }
    if (error.response?.status === 500) {
      throw new Error('Internal Server Error');
    }
    throw error;
  }
}

type User = {
  username: string;
  accessToken: string;
};

type ExportUsersButtonProps = {
  user: User;
  onRequestLogout: () => void;
  showError: (errorTitle: string, errorMessage: string) => void;
};

type ExportUsersButtonState = {
  loading: boolean;
};

export default class ExportUsersButton extends React.Component<
  ExportUsersButtonProps,
  ExportUsersButtonState
> {
  state: ExportUsersButtonState = {
    loading: false,
  };

  handleExportUsers = async (): Promise<void> => {
    try {
      this.setState({ loading: true });
      await fetchUsers(
        this.props.user !== null ? this.props.user.accessToken : '',
      );
      this.setState({
        loading: false,
      });
    } catch (error) {
      if (
        error.message === 'Network Error' ||
        error.message === 'Internal Server Error'
      ) {
        this.props.showError(
          'Error de conexión',
          'Ocurrió un error de conexión. Intenta más tarde.',
        );
      } else if (error.message === 'Forbidden') {
        this.props.showError(
          'Sesion expirada',
          'Tu sesión expiró. Por favor ingresa nuevamente.',
        );
        this.props.onRequestLogout();
      } else {
        this.props.showError(
          'Error desconocido',
          'Ocurrió un error. Intenta más tarde.',
        );
      }
    }
  };

  render(): JSX.Element {
    return (
      <Button
        onClick={this.handleExportUsers}
        icon={<SvgExport />}
        size="small"
        variant="orange"
      >
        {this.state.loading ? 'Exportando...' : 'Exportar usuarios'}
      </Button>
    );
  }
}
