import React from 'react';
import styled from '@emotion/styled';
import { Select } from 'antd';

import { ReactComponent as SvgCalendar } from '../svg/calendar.svg';

const Wrapper = styled.div({
  display: 'flex',
  border: '1px solid #EBEBEB',
  borderRadius: 20,
  paddingRight: 10,
  '@media(min-width: 800px)': {},
  '.ant-select': {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
  },
  '.ant-select-single:not(.ant-select-cuztomize-input) .ant-select-selector': {
    border: 'none',
    fontFamily: 'Helvetica Neue',
    fontSize: 14,
    lineHeight: 20 / 14,
  },
});

const Icon = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '7px 0 7px 14px',
  '@media(min-width: 800px)': {},
});

type UserStatsYearSelectProps = {
  value: string;
  onChange: (year: string) => void;
};

function getAvailableYears(): number[] {
  let start = 2020;
  const current = new Date().getFullYear();
  const options = [];
  while (start <= current) {
    options.push(start);
    start++;
  }
  return options;
}

export default class UserStatsYearSelect extends React.Component<
  UserStatsYearSelectProps
> {
  handleYearChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    this.props.onChange(value);
  };

  render(): JSX.Element {
    const availableYears = getAvailableYears();

    return (
      <Wrapper>
        <Icon>
          <SvgCalendar />
        </Icon>
        <Select value={this.props.value} onChange={this.props.onChange}>
          {availableYears.map((year) => (
            <Select.Option key={year} value={year}>
              {year}
            </Select.Option>
          ))}
        </Select>
      </Wrapper>
    );
  }
}
