import React from 'react';
import axios from 'axios';
import styled from '@emotion/styled';

import UserListItem from './UserListItem';
import CustomPagination from './Table/CustomPagination';
import CustomLoading from './CustomLoading';

const UserListHeader = styled.div({
  display: 'none',
  padding: '4px 0',
  borderRadius: '20px',
  borderBottom: '1px solid #F5F5F5',
  fontFamily: 'Helvetica Neue',
  fontSize: 14,
  lineHeight: 20 / 14,
  color: '#464646',
  backgroundColor: '#F0F0F0',
  '@media(min-width: 800px)': {
    display: 'flex',
  },
});

type UserListHeaderItemProps = {
  width?: string;
  onClick?: () => void;
};

const UserListHeaderItem = styled('div')<UserListHeaderItemProps>(
  {},
  (props) => {
    const width = props.width || '100%';
    const canClick = Boolean(props.onClick);
    return {
      cursor: canClick ? 'pointer' : 'default',
      width: '100%',
      flexGrow: 1,
      padding: '5px 29px',
      color: '#505050',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '@media(min-width: 800px)': {
        width,
      },
    };
  },
);

const UserListButtonsHeader = styled('div')<UserListHeaderItemProps>(
  {
    flexShrink: 0,
    '@media(min-width: 800px)': {
      margin: '-8px -10px -8px 0',
    },
  },
  (props) => {
    const width = props.width || '100%';
    return {
      width: '100%',
      '@media(min-width: 800px)': {
        width,
      },
    };
  },
);

const UserListContainer = styled.div({
  position: 'relative',
});

type ApiUser = {
  id: number;
  rut: string;
  levelOne: string;
  levelTwo: string;
  levelThree: string;
  jobTitle: string;
  unit: string;
  paternalSurname: string;
  maternalSurname: string;
  firstname: string;
  birthdate: string;
  sex: string;
  email: string;
  managerCode: string;
  isManager: boolean;
  subdivision: string;
  zone: string;
  createdAt: string;
  updatedAt: string;
  lastLogin: string | null;
};

type FetchUsersData = {
  users: ApiUser[];
  count: number;
  total: number;
};

// Load users request
async function fetchUsers(
  authToken: string,
  offset: number,
  limit: number,
  sortField?: string,
  sortDirection?: 'ASC' | 'DESC',
  search?: string,
): Promise<FetchUsersData> {
  const baseUrl = process.env.REACT_APP_API_URL;
  try {
    const res = await axios.request({
      method: 'get',
      url: `${baseUrl}/admin/users`,
      headers: { Authorization: 'Bearer ' + authToken },
      params: { offset, limit, sort: sortField, order: sortDirection, search },
    });
    const data: FetchUsersData = res.data.data;
    return data;
  } catch (error) {
    if (error.response?.status === 401) {
      throw new Error('Unauthorized');
    }
    if (error.response?.status === 403) {
      throw new Error('Forbidden');
    }
    if (error.response?.status === 500) {
      throw new Error('Internal Server Error');
    }
    throw error;
  }
}

type User = { username: string; accessToken: string };

type UserListProps = {
  user: User;
  showError: (errorTitle: string, errorMessage: string) => void;
  onRequestLogout: () => void;
  refreshRequested: boolean;
  resetRefreshRequested: () => void;
  search: string;
  redirectToEditUser: (id: number) => void;
};

type SortField = 'paternalSurname' | 'lastLogin';
type SortDirection = 'ASC' | 'DESC';

type UserListState = {
  users: ApiUser[];
  page: number;
  pageSize: number;
  totalItems: number;
  loadingState: 'loading' | 'loaded' | 'error';
  sortField: SortField;
  sortDirection: SortDirection;
};

const initialState: UserListState = {
  users: [],
  page: 1,
  pageSize: 20,
  totalItems: 0,
  loadingState: 'loading',
  sortField: 'paternalSurname',
  sortDirection: 'ASC',
};

export default class UserList extends React.Component<
  UserListProps,
  UserListState
> {
  state: UserListState = initialState;

  componentDidMount(): void {
    this.handleLoadUsers();
  }

  componentDidUpdate(prevProps: UserListProps): void {
    if (prevProps.refreshRequested) {
      this.props.resetRefreshRequested();
      this.handleLoadUsers();
    }

    if (prevProps.search !== this.props.search) {
      this.handleLoadUsers(
        1,
        this.state.pageSize,
        this.state.sortField,
        this.state.sortDirection,
        this.props.search,
      );
    }
  }

  setLoading = (): void => {
    this.setState({ loadingState: 'loading' });
  };

  setError = (): void => {
    this.setState({ loadingState: 'error' });
  };

  handleLoadUsers = async (
    page = 1,
    pageSize = 20,
    sortField: SortField = 'paternalSurname',
    sortDirection: SortDirection = 'ASC',
    search = '',
  ): Promise<void> => {
    try {
      this.setLoading();
      const offset = (page - 1) * pageSize;
      const response = await fetchUsers(
        this.props.user.accessToken,
        offset,
        pageSize,
        sortField,
        sortDirection,
        search,
      );
      this.setState({
        users: response.users,
        page,
        pageSize,
        totalItems: response.total,
        loadingState: 'loaded',
        sortField,
        sortDirection,
      });
    } catch (error) {
      this.setError();
      if (error.message === 'Unauthorized') {
        this.props.showError(
          '¡Credenciales inválidas!',
          'Tus credenciales no son correctas. Inténtalo de nuevo.',
        );
      } else if (
        error.message === 'Network Error' ||
        error.message === 'Internal Server Error'
      ) {
        this.props.showError(
          'Error de conexión',
          'Ocurrió un error de conexión. Intenta más tarde.',
        );
      } else if (error.message === 'Forbidden') {
        this.props.showError(
          'Sesion expirada',
          'Tu sesión expiró. Por favor ingresa nuevamente.',
        );
        this.props.onRequestLogout();
      } else {
        this.props.showError(
          'Error desconocido',
          'Ocurrió un error. Intenta más tarde.',
        );
      }
    }
  };

  handlePageChange = (page: number): void => {
    this.setState({ page });
    this.handleLoadUsers(
      page,
      this.state.pageSize,
      this.state.sortField,
      this.state.sortDirection,
      this.props.search,
    );
  };

  handlePageSizeChange = (pageSize: number): void => {
    this.setState({ page: 1, pageSize: pageSize });
    this.handleLoadUsers(
      1,
      pageSize,
      this.state.sortField,
      this.state.sortDirection,
      this.props.search,
    );
  };

  handleSortChange = (sortField: SortField): void => {
    const oppositeDirection =
      this.state.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    const sortDirection =
      this.state.sortField === sortField ? oppositeDirection : 'ASC';
    this.setState({ sortField, sortDirection });
    this.handleLoadUsers(
      this.state.page,
      this.state.pageSize,
      sortField,
      sortDirection,
      this.props.search,
    );
  };

  handleUserDeleted = (): void => {
    // Refresh user list after user is deleted
    this.handleLoadUsers(
      this.state.page,
      this.state.pageSize,
      this.state.sortField,
      this.state.sortDirection,
      this.props.search,
    );
  };

  render(): JSX.Element {
    const { totalItems, pageSize, page } = this.state;

    return (
      <UserListContainer>
        {this.state.loadingState === 'loading' ? <CustomLoading /> : null}

        {this.state.loadingState === 'error' && this.state.users.length === 0
          ? 'No se pudo cargar usuarios.'
          : null}

        {this.state.loadingState === 'loaded' && this.state.users.length === 0
          ? 'No se encontraron usuarios.'
          : null}

        {this.state.users.length > 0 ? (
          <>
            <UserListHeader>
              <UserListHeaderItem width="60%">Nombre</UserListHeaderItem>
              <UserListHeaderItem
                width="80%"
                onClick={(): void => {
                  this.handleSortChange('paternalSurname');
                }}
              >
                Apellidos
              </UserListHeaderItem>
              <UserListHeaderItem width="50%">RUT</UserListHeaderItem>
              <UserListHeaderItem>Correo</UserListHeaderItem>
              <UserListHeaderItem
                width="60%"
                onClick={(): void => {
                  this.handleSortChange('lastLogin');
                }}
              >
                Último inicio de sesión
              </UserListHeaderItem>
              <UserListButtonsHeader width="96px"> </UserListButtonsHeader>
            </UserListHeader>
            {this.state.users.map(
              ({
                id,
                rut,
                firstname,
                paternalSurname,
                maternalSurname,
                email,
                lastLogin,
              }: ApiUser): JSX.Element => {
                return (
                  <UserListItem
                    key={id}
                    id={id}
                    rut={rut}
                    firstname={firstname}
                    paternalSurname={paternalSurname}
                    maternalSurname={maternalSurname}
                    email={email}
                    lastLogin={lastLogin}
                    onDelete={this.handleUserDeleted}
                    onEdit={this.props.redirectToEditUser}
                  />
                );
              },
            )}

            <CustomPagination
              page={page}
              pageSize={pageSize}
              totalItems={totalItems}
              handlePageChange={this.handlePageChange}
              handlePageSizeChange={this.handlePageSizeChange}
            />
          </>
        ) : null}
      </UserListContainer>
    );
  }
}
