import React from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import HeaderButton from './HeaderButton';
import { ReactComponent as SvgLogout } from '../svg/logout.svg';
import { AppContext } from '../context/app-context';
import { ReactComponent as LogoSvg } from '../svg/logo_mutual20.svg';

const HeaderContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignSelf: 'stretch',
  padding: '10px 20px 10px 0',
  backgroundColor: '#8FBE00',
  textAlign: 'right',
  marginBottom: '20px',
});

const SectionTitle = styled.h1({
  fontSize: 20,
  lineHeight: 25 / 20,
  padding: '7px 27px',
  color: '#ffffff',
});

const LogoutIcon = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingRight: 10,
});

const Navigation = styled.nav({
  textAlign: 'left',
  'a, a:active, a:hover': {
    color: '#ffffff',
  },
  '.active': {
    fontFamily: 'Helvetica Neue Bold',
    borderBottom: '3px solid #fff',
  },
  '@media(min-width: 800px)': {
    display: 'flex',
    justifyContent: 'flex-start',
    flexBasis: '50%',
  },
});

const Logo = styled.div({
  flexBasis: '36px',
  padding: '0 10px',
});

const Buttons = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
  '@media(min-width: 800px)': {
    flexBasis: '50%',
  },
});

type Props = {
  title?: string;
};

export default function Header(props: Props): JSX.Element {
  return (
    <AppContext.Consumer>
      {(ctx): JSX.Element => {
        return (
          <HeaderContainer>
            {props.title ? (
              <Navigation>
                <SectionTitle>{props.title}</SectionTitle>
              </Navigation>
            ) : (
              <Navigation>
                <SectionTitle>
                  <NavLink to="/users">Usuarios</NavLink>
                </SectionTitle>
                <SectionTitle>
                  <NavLink to="/user-activity">Análisis de ingresos</NavLink>
                </SectionTitle>
                <SectionTitle>
                  <NavLink to="/comments">Comentarios</NavLink>
                </SectionTitle>
              </Navigation>
            )}

            <Logo>
              <LogoSvg width="36" height="42" />
            </Logo>

            <Buttons>
              <HeaderButton onClick={ctx.logout}>
                <LogoutIcon>
                  <SvgLogout />
                </LogoutIcon>
                <div>Cerrar sesión</div>
              </HeaderButton>
            </Buttons>
          </HeaderContainer>
        );
      }}
    </AppContext.Consumer>
  );
}
