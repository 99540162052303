import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

// Función que deja el text sólo con la primera letra mayuscula y el resto del
// texto en minusculas.
function firstLetterUppercase(text: string | null): string {
  if (text !== null) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  } else {
    return '';
  }
}

export function formatName(text: string | null): string {
  if (text !== null) {
    const words = text.split(/ +/);
    return words.map(firstLetterUppercase).join(' ');
  } else {
    return '';
  }
}

export function formatDate(apiDate: string | null): string {
  if (!apiDate) {
    return '';
  }
  const date = parseISO(apiDate);
  return format(date, 'dd/LL/yyyy', { locale: es });
}

export function formatDateTime(apiDate: string | null): string {
  if (!apiDate) {
    return '';
  }
  const date = parseISO(apiDate);
  return format(date, 'dd/LL/yyyy HH:mm', { locale: es });
}
