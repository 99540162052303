import React from 'react';
import styled from '@emotion/styled';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import { Tooltip } from 'antd';

import { formatName } from '../utils/format';
import { AppContext } from '../context/app-context';
import Modal from './Modal';
import DeleteUser from './DeleteUser';
import ResetUserPassword from './ResetUserPassword';
import { ReactComponent as SvgEdit } from '../svg/edit.svg';
import { ReactComponent as SvgTrash } from '../svg/trash.svg';
import { ReactComponent as SvgKey } from '../svg/key.svg';

function formatDate(apiDate: string | null): string {
  if (!apiDate) {
    return '';
  }
  const date = parseISO(apiDate);
  return format(date, 'dd/LL/yyyy', { locale: es });
}

function formatDateTime(apiDate: string | null): string {
  if (!apiDate) {
    return '';
  }
  const date = parseISO(apiDate);
  return format(date, 'dd/LL/yyyy HH:mm', { locale: es });
}

const StyledUserListItem = styled.div({
  padding: '10px 0',
  borderTop: '1px solid #F5F5F5',
  fontFamily: 'Helvetica Neue Bold',
  fontSize: 16,
  lineHeight: 19 / 16,
  color: '#464646',
  '@media(min-width: 800px)': {
    display: 'flex',
  },
});

const MobileLabel = styled.span({
  display: 'inline',
  '@media(min-width: 800px)': {
    display: 'none',
  },
});

const Email = styled.span({
  fontFamily: 'Helvetica Neue',
  color: '#F19334',
});

const ActionButton = styled.button({
  width: 32,
  border: 'none',
  padding: '12px 7px',
  cursor: 'pointer',
  background: 'none',
});

type UserListItemFieldProps = {
  width?: string | number;
};

const UserListItemField = styled('div')<UserListItemFieldProps>(
  {
    flexGrow: 1,
    padding: '5px 29px',
    color: '#505050',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  (props) => {
    const width = props.width || '100%';
    return {
      width: '100%',
      '@media(min-width: 800px)': {
        width,
      },
    };
  },
);

const UserListItemButtons = styled('div')<UserListItemFieldProps>(
  {
    flexShrink: 0,
    padding: '5px 17px',
    '@media(min-width: 800px)': {
      padding: 0,
      margin: '-8px -7px -8px 0',
    },
  },
  (props) => {
    const width = props.width || '100%';
    return {
      width: '100%',
      '@media(min-width: 800px)': {
        width,
      },
    };
  },
);

type UserListItemProps = {
  id: number;
  rut: string;
  firstname: string;
  paternalSurname: string;
  maternalSurname: string;
  email: string;
  lastLogin: string | null;
  onDelete: () => void;
  onEdit: (id: number) => void;
};

type UserListItemState = {
  isDeleteConfirmationOpen: boolean;
  isResetPasswordConfirmationOpen: boolean;
};

export default class UserListItem extends React.Component<
  UserListItemProps,
  UserListItemState
> {
  state = {
    isDeleteConfirmationOpen: false,
    isResetPasswordConfirmationOpen: false,
  };

  handleDeleteUser = (): void => {
    this.setState({ isDeleteConfirmationOpen: true });
  };

  handleResetPassword = (): void => {
    this.setState({ isResetPasswordConfirmationOpen: true });
  };

  handleEditUser = (): void => {
    this.props.onEdit(this.props.id);
  };

  render(): JSX.Element {
    const {
      rut,
      firstname,
      paternalSurname,
      maternalSurname,
      email,
      lastLogin,
    } = this.props;

    return (
      <StyledUserListItem key={rut}>
        <UserListItemField width="60%">
          {formatName(firstname)}
        </UserListItemField>
        <UserListItemField width="80%">
          {formatName(paternalSurname)} {formatName(maternalSurname)}
        </UserListItemField>
        <UserListItemField width="50%">{rut}</UserListItemField>
        <UserListItemField>
          <Email>{email ? email.toLowerCase() : ''}</Email>
        </UserListItemField>
        <UserListItemField
          title={lastLogin ? formatDateTime(lastLogin) : undefined}
          width="60%"
        >
          <MobileLabel>Último inicio de sesión: </MobileLabel>
          {formatDate(lastLogin)}
        </UserListItemField>
        <UserListItemButtons width="auto">
          <ActionButton onClick={this.handleResetPassword}>
            <Tooltip
              title="Restablecer contraseña del usuario"
              arrowPointAtCenter
              placement="bottomRight"
              // Error in tooltip typescript definition throws compilation
              // errors when passing a custom color
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              color="#8EC000"
            >
              <SvgKey />
            </Tooltip>
          </ActionButton>
          <ActionButton onClick={this.handleEditUser}>
            <SvgEdit />
          </ActionButton>
          <ActionButton onClick={this.handleDeleteUser}>
            <SvgTrash />
          </ActionButton>
        </UserListItemButtons>

        {/* Reset password modal */}
        <Modal
          isOpen={this.state.isResetPasswordConfirmationOpen}
          onRequestClose={(): void => {
            // TODO: fix the modal so it doesn't close when clicking
            // anywhere
            //this.setState({ isDeleteConfirmationOpen: false });
          }}
        >
          <AppContext.Consumer>
            {({ user, showError, logout }): JSX.Element => {
              return (
                <ResetUserPassword
                  rut={rut}
                  onRequestClose={(): void => {
                    this.setState({ isResetPasswordConfirmationOpen: false });
                  }}
                  onRequestLogout={logout}
                  showError={showError}
                />
              );
            }}
          </AppContext.Consumer>
        </Modal>

        {/* Delete user modal */}
        <Modal
          isOpen={this.state.isDeleteConfirmationOpen}
          onRequestClose={(): void => {
            // TODO: fix the modal so it doesn't close when clicking
            // anywhere
            //this.setState({ isDeleteConfirmationOpen: false });
          }}
        >
          <AppContext.Consumer>
            {({ user, showError, logout }): JSX.Element => {
              return (
                <DeleteUser
                  accessToken={user ? user.accessToken : ''}
                  userId={this.props.id}
                  onRequestClose={(): void => {
                    this.setState({ isDeleteConfirmationOpen: false });
                  }}
                  onRequestLogout={logout}
                  onDelete={this.props.onDelete}
                  showError={showError}
                />
              );
            }}
          </AppContext.Consumer>
        </Modal>
      </StyledUserListItem>
    );
  }
}
