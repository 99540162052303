import React from 'react';
import styled from '@emotion/styled';

import Modal from './Modal';

const MessageModalContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  width: 'calc(100vw - 120px)',
  maxWidth: 345,
});
const MessageModalLogo = styled.div({
  padding: 12,
});
const MessageModalTitle = styled.div({
  padding: 12,
  fontSize: 22,
  fontFamily: 'Helvetica Neue Medium',
  lineHeight: 1,
  textAlign: 'center',
  color: '#505050',
});
const MessageModalMessage = styled.div({
  padding: 12,
  fontSize: 18,
  fontFamily: 'Helvetica Neue Light',
  lineHeight: 1.2,
  textAlign: 'center',
  color: '#9A9797',
});

type MessageModalProps = {
  isOpen: boolean;
  title: string;
  message: string;
  onRequestClose: () => void;
  icon: React.ReactNode;
};

export default function MessageModal(props: MessageModalProps): JSX.Element {
  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onRequestClose}>
      <MessageModalContainer>
        <MessageModalLogo>{props.icon}</MessageModalLogo>
        <MessageModalTitle>{props.title}</MessageModalTitle>
        <MessageModalMessage>{props.message}</MessageModalMessage>
      </MessageModalContainer>
    </Modal>
  );
}
