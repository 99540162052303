import React from 'react';
import styled from '@emotion/styled';
import axios from 'axios';

import { ReactComponent as SvgQuestion } from '../svg/question.svg';
import { ReactComponent as SvgWarning } from '../svg/warning.svg';
import { ReactComponent as SvgCheck } from '../svg/check.svg';

import Button from './Button';

type DeleteUserProps = {
  accessToken: string;
  userId: number;
  onRequestClose: () => void;
  onDelete: () => void;
  showError: (errorTitle: string, errorMessage: string) => void;
  onRequestLogout: () => void;
};

type DeleteUserState = {
  deleting: boolean;
  errorMessage: string;
  deleteSuccess: boolean;
};

export default class DeleteUser extends React.Component<
  DeleteUserProps,
  DeleteUserState
> {
  state: DeleteUserState = {
    deleting: false,
    errorMessage: '',
    deleteSuccess: false,
  };

  handleDelete = async (): Promise<void> => {
    this.setState({
      deleting: true,
    });

    try {
      const baseUrl = process.env.REACT_APP_API_URL;
      const authToken = this.props.accessToken;
      const userId = this.props.userId;
      await axios.request({
        method: 'delete',
        url: `${baseUrl}/admin/users/${userId}`,
        headers: { Authorization: 'Bearer ' + authToken },
      });
      this.setState({
        deleting: false,
      });
      this.setState({
        deleteSuccess: true,
        deleting: false,
        errorMessage: '',
      });
    } catch (error) {
      let errorMessage = '';
      // Handle not found error in this modal
      // Show global message and close modal for other errors
      if (error.response?.status === 404) {
        errorMessage = 'Usuario no encontrado.';
      } else if (error.response?.status === 403) {
        this.props.showError(
          'Sesion expirada',
          'Tu sesión expiró. Por favor ingresa nuevamente.',
        );
        this.props.onRequestLogout();
      } else if (
        error.response?.status === 500 ||
        error.message === 'Network Error'
      ) {
        this.props.showError(
          'Error de conexión',
          'Ocurrió un error de conexión. Intenta más tarde.',
        );
        this.props.onRequestClose();
      } else {
        this.props.showError(
          'Error desconocido',
          'Ocurrió un error. Intenta más tarde.',
        );
        this.props.onRequestClose();
      }
      this.setState({
        deleting: false,
        errorMessage,
      });
    }
  };

  handleCloseDeleted = (): void => {
    this.props.onRequestClose();
    this.props.onDelete();
  };

  render(): JSX.Element {
    return (
      <Container>
        {this.state.deleteSuccess ? (
          <>
            <Header>
              <Icon>
                <SvgCheck width={48} height={48} />
              </Icon>
              <Title>¡Usuario eliminado con éxito!</Title>
            </Header>
            <Buttons>
              <CloseButton>
                <Button onClick={this.handleCloseDeleted}>Cerrar</Button>
              </CloseButton>
            </Buttons>
          </>
        ) : this.state.errorMessage === '' ? (
          <>
            <Header>
              <Icon>
                <SvgQuestion />
              </Icon>
              <Title>¿Estás seguro de eliminar este usuario?</Title>
            </Header>
            <Message>
              Una vez eliminado no podrás verlo en tus registros ni volver a
              activarlo.
            </Message>
            <Buttons>
              <ButtonPadding>
                <Button
                  variant="orange"
                  onClick={this.props.onRequestClose}
                  fullWidth
                >
                  Cancelar
                </Button>
              </ButtonPadding>

              <ButtonPadding>
                <Button
                  onClick={this.handleDelete}
                  disabled={this.state.deleting}
                  fullWidth
                >
                  {this.state.deleting ? 'Eliminando...' : 'Eliminar'}
                </Button>
              </ButtonPadding>
            </Buttons>
          </>
        ) : (
          <>
            <Header>
              <Icon>
                <SvgWarning width={48} height={48} />
              </Icon>
              <Title>¡Eliminación de usuario fallida!</Title>
            </Header>
            <Message>{this.state.errorMessage}</Message>
            <Buttons>
              <CloseButton>
                <Button onClick={this.props.onRequestClose}>Cerrar</Button>
              </CloseButton>
            </Buttons>
          </>
        )}
      </Container>
    );
  }
}

const Container = styled.div({
  width: 'calc(100vw - 160px)',
  maxWidth: 615,
});

const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: 30,
});

const Icon = styled.div({
  paddingRight: 12,
  color: '#E9468C',
});

const Title = styled.div({
  font: 'Medium 30px/13px Helvetica Neue',
  fontFamily: 'Helvetica Neue Medium',
  fontSize: 30,
  lineHeight: 40 / 30,
  letterSpacing: 0,
  color: '#505050',
  opacity: 1,
});

const Message = styled.div({
  fontFamily: 'Helvetica Neue Light',
  fontSize: 20,
  lineHeight: 30 / 20,
  textAlign: 'center',
  paddingTop: 0,
  paddingBottom: 65,
});

const Buttons = styled.div({
  paddingVertical: 30,
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'stretch',
  marginLeft: -7,
  marginRight: -7,
});

const ButtonPadding = styled.div({
  width: '100%',
  paddingLeft: 7,
  paddingRight: 7,
});

const CloseButton = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
});
