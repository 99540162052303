import React from 'react';
import styled from '@emotion/styled';

import CheckSVG from './svg/CheckSVG';
import RejectSVG from './svg/RejectSVG';
import CustomLink from './CustomLink';
import CustomPagination from './Table/CustomPagination';
import CustomTable from './Table/CustomTable';

import { formatDate, formatName } from '../utils/format';
import { colors } from '../constants/colors';
import { getUrlContentfulContent } from '../utils/contenful';
import CustomLoading from './CustomLoading';
import { requestCommentList, patchCommentStatus } from '../api/request';

const CommentListContainer = styled.div({
  position: 'relative',
});

type ApiComment = {
  id: number;
  user: {
    rut: string;
    paternalSurname: string;
    maternalSurname: string;
    firstname: string;
  };
  userId: string;
  status: string;
  contentId: string;
  contentTitle: string;
  text: string;
  createdAt: string;
  updatedAt: string;
};

type FetchCommentsData = {
  comments: ApiComment[];
  total: number;
};

type User = { username: string; accessToken: string };

type CommentListProps = {
  user: User;
  showError: (errorTitle: string, errorMessage: string) => void;
  onRequestLogout: () => void;
  refreshRequested: boolean;
  resetRefreshRequested: () => void;
};

type SortField =
  | 'userName'
  | 'userRut'
  | 'text'
  | 'createdAt'
  | 'contentTitle'
  | 'status';

type SortDirection = 'ASC' | 'DESC';

type CommentListState = {
  comments: ApiComment[];
  page: number;
  pageSize: number;
  totalItems: number;
  loadingState: 'loading' | 'loaded' | 'error';
  sortField: SortField;
  sortDirection: SortDirection;
};

const initialState: CommentListState = {
  comments: [],
  page: 1,
  pageSize: 20,
  totalItems: 0,
  loadingState: 'loading',
  sortField: 'createdAt',
  sortDirection: 'DESC',
};

export default class CommentList extends React.Component<
  CommentListProps,
  CommentListState
> {
  state: CommentListState = initialState;

  componentDidMount(): void {
    this.handleLoadComments();
  }

  componentDidUpdate(prevProps: CommentListProps): void {
    if (prevProps.refreshRequested) {
      this.props.resetRefreshRequested();
      this.handleLoadComments();
    }
  }

  setLoading = (): void => {
    this.setState({ loadingState: 'loading' });
  };

  setError = (): void => {
    this.setState({ loadingState: 'error' });
  };

  handleLoadComments = async (
    page = 1,
    pageSize = 20,
    sortField: SortField = 'createdAt',
    sortDirection: SortDirection = 'DESC',
  ): Promise<void> => {
    try {
      this.setLoading();

      const { accessToken } = this.props.user;
      const offset = (page - 1) * pageSize;

      const res = await requestCommentList(accessToken, {
        offset,
        limit: pageSize,
        sort: sortField,
        order: sortDirection,
      });

      const data: FetchCommentsData = res.data;

      if (data?.comments && data?.comments.length) {
        data.comments = data.comments.filter(c => c?.user && c?.user.rut)
      }

      this.setState({
        comments: data.comments,
        page,
        pageSize,
        totalItems: data.total,
        loadingState: 'loaded',
        sortField,
        sortDirection,
      });
    } catch (error) {
      this.setError();
      if (error.response?.status === 401) {
        this.props.showError(
          '¡Credenciales inválidas!',
          'Tus credenciales no son correctas. Inténtalo de nuevo.',
        );
      } else if (error.response?.status === 500) {
        this.props.showError(
          'Error de conexión',
          'Ocurrió un error de conexión. Intenta más tarde.',
        );
      } else if (error.response?.status === 403) {
        this.props.showError(
          'Sesion expirada',
          'Tu sesión expiró. Por favor ingresa nuevamente.',
        );
        this.props.onRequestLogout();
      } else {
        this.props.showError(
          'Error desconocido',
          'Ocurrió un error. Intenta más tarde.',
        );
      }
    }
  };

  getResults = () => {
    const { page, pageSize, sortField, sortDirection } = this.state;
    this.handleLoadComments(page, pageSize, sortField, sortDirection);
  };

  handlePageChange = (page: number): void => {
    this.setState({ page }, () => this.getResults());
  };

  handlePageSizeChange = (pageSize: number): void => {
    this.setState({ page: 1, pageSize: pageSize }, () => this.getResults());
  };

  handleSortChange = (sortField: SortField): void => {
    const oppositeDirection =
      this.state.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    const sortDirection =
      this.state.sortField === sortField ? oppositeDirection : 'ASC';
    this.setState({ sortField, sortDirection }, () => this.getResults());
  };

  setStatusComment = async (
    commentId: number,
    status: 'approved' | 'rejected' | 'new',
  ) => {
    try {
      const res = await patchCommentStatus(
        this.props.user.accessToken,
        commentId,
        status,
      );

      if (res && res.data) {
        // Actualizo el listado de comentarios con el nuevo estado
        this.getResults();
      }
    } catch (error) {
      this.setError();
      if (error.response?.status === 500) {
        this.props.showError(
          'Error de conexión',
          'Ocurrió un error de conexión. Intenta más tarde.',
        );
      } else if (error.response?.status === 403) {
        this.props.showError(
          'Sesion expirada',
          'Tu sesión expiró. Por favor ingresa nuevamente.',
        );
        this.props.onRequestLogout();
      } else {
        this.props.showError(
          'Error desconocido',
          'Ocurrió un error. Intenta más tarde.',
        );
      }
    }
  };

  getActionButtons = (id: number, status: string) => {
    return {
      value: [
        {
          tooltip: 'Aprobar Comentario',
          actionButton: () => this.setStatusComment(id, 'approved'),
          icon: (
            <CheckSVG
              color={status === 'approved' ? colors.GREEN : colors.GRAY}
            />
          ),
        },
        {
          tooltip: 'Rechazar Comentario',
          actionButton: () => this.setStatusComment(id, 'rejected'),
          icon: (
            <RejectSVG
              color={status === 'rejected' ? colors.RED : colors.GRAY}
            />
          ),
        },
      ],
      format: 'actions',
    };
  };

  render(): JSX.Element {
    const { totalItems, pageSize, page, comments, loadingState } = this.state;
    const columns = [
      {
        dataField: 'user',
        text: 'Usuario',
        width: '20%',
      },
      {
        dataField: 'rut',
        text: 'RUT',
        width: '15%',
      },
      {
        dataField: 'comment',
        text: 'Comentario',
        width: '30%',
      },
      {
        dataField: 'date',
        text: 'Fecha',
        width: '15%',
        onPressHeader: (): void => {
          this.handleSortChange('createdAt');
        },
      },
      {
        dataField: 'content',
        text: 'Comunicado',
        width: '20%',
      },
      {
        dataField: 'actions',
        text: '',
        width: 'auto',
        onPressHeader: (): void => {
          this.handleSortChange('status');
        },
      },
    ];
    return (
      <CommentListContainer>
        {loadingState === 'loading' ? <CustomLoading /> : null}

        {loadingState === 'error' && comments.length === 0
          ? 'No se pudo cargar el listado de comentarios.'
          : null}

        {loadingState === 'loaded' && comments.length === 0
          ? 'No se encontraron comentarios.'
          : null}

        {comments.length > 0 ? (
          <>
            <CustomTable
              columns={columns}
              data={comments.map(
                ({
                  id,
                  user,
                  contentId,
                  contentTitle,
                  text,
                  createdAt,
                  status,
                }: ApiComment) => {
                  return {
                    rut: { value: user.rut },
                    user: {
                      value: formatName(
                        `${user.firstname} ${user.paternalSurname}`,
                      ),
                    },
                    comment: { value: text, style: { fontSize: '11px' } },
                    date: { value: formatDate(createdAt) },
                    content: {
                      value: (
                        <CustomLink
                          url={getUrlContentfulContent(contentId)}
                          title={contentTitle}
                        />
                      ),
                    },
                    actions: this.getActionButtons(id, status),
                  };
                },
              )}
            />
            <CustomPagination
              page={page}
              pageSize={pageSize}
              totalItems={totalItems}
              handlePageChange={this.handlePageChange}
              handlePageSizeChange={this.handlePageSizeChange}
            />
          </>
        ) : null}
      </CommentListContainer>
    );
  }
}
