import React from 'react';

type CheckSVGProps = {
  color?: string;
  width?: number;
  height?: number;
};

const CheckSVG = ({
  color = '#8fbe00',
  width = 18,
  height = 18,
}: CheckSVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 48 48"
    >
      <g
        id="Grupo_1812"
        data-name="Grupo 1812"
        transform="translate(-4751 -2298)"
      >
        <g id="warning" transform="translate(4751 2298)">
          <g id="Grupo_338" data-name="Grupo 338">
            <g id="Grupo_337" data-name="Grupo 337">
              <path
                id="Trazado_2623"
                data-name="Trazado 2623"
                d="M24,0A24,24,0,1,0,48,24,23.986,23.986,0,0,0,24,0Zm0,44.25A20.25,20.25,0,1,1,44.25,24,20.239,20.239,0,0,1,24,44.25Z"
                fill={color}
              />
            </g>
          </g>
        </g>
        <g
          id="Grupo_1807"
          data-name="Grupo 1807"
          transform="translate(4763.698 2314.344)"
        >
          <g id="tick" transform="translate(0 0)">
            <g id="Grupo_268" data-name="Grupo 268" transform="translate(0 0)">
              <path
                id="Trazado_2600"
                data-name="Trazado 2600"
                d="M22.732,68.335a1.153,1.153,0,0,0-1.631,0L7.281,82.156,1.969,76.843A1.154,1.154,0,1,0,.337,78.475L6.465,84.6a1.154,1.154,0,0,0,1.631,0L22.732,69.967A1.154,1.154,0,0,0,22.732,68.335Z"
                transform="translate(0 -67.997)"
                fill={color}
                stroke={color}
                strokeWidth="1"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default CheckSVG;
