import React from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import UserStatsTableItem from './UserStatsTableItem';

const UserStatsTableHeader = styled.div({
  display: 'flex',
  padding: '4px 0',
  borderRadius: '20px',
  borderBottom: '1px solid #F5F5F5',
  fontFamily: 'Helvetica Neue',
  fontSize: 14,
  lineHeight: 20 / 14,
  color: '#464646',
  backgroundColor: '#F0F0F0',
  '@media(min-width: 800px)': {},
});

const NumberHeader = styled.div({
  textAlign: 'right',
});

type UserStatsTableHeaderItemProps = {
  width?: string;
  onClick?: () => void;
};

const UserStatsTableHeaderItem = styled('div')<UserStatsTableHeaderItemProps>(
  {},
  (props) => {
    const width = props.width || '100%';
    const canClick = Boolean(props.onClick);
    return {
      cursor: canClick ? 'pointer' : 'default',
      width: '100%',
      flexGrow: 1,
      padding: '5px 29px',
      color: '#505050',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '@media(min-width: 800px)': {
        width,
      },
    };
  },
);

const Loading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.25)',
});

const UserStatsTableContainer = styled.div({
  position: 'relative',
});

type UserMonthStats = {
  month: string;
  totalAppLoads: number;
  uniqueUsers: number;
  newUsers: number;
};

type FetchUserStatsData = { stats: UserMonthStats[] };

async function fetchUserStats(
  authToken: string,
  year: string,
): Promise<FetchUserStatsData> {
  const baseUrl = process.env.REACT_APP_API_URL;
  try {
    const res = await axios.request({
      method: 'get',
      url: `${baseUrl}/admin/users/stats`,
      headers: { Authorization: 'Bearer ' + authToken },
      params: { year },
    });
    const data: FetchUserStatsData = res.data.data;
    return data;
  } catch (error) {
    if (error.response?.status === 401) {
      throw new Error('Unauthorized');
    }
    if (error.response?.status === 403) {
      throw new Error('Forbidden');
    }
    if (error.response?.status === 500) {
      throw new Error('Internal Server Error');
    }
    throw error;
  }
}

type User = { username: string; accessToken: string };

type UserStatsTableProps = {
  user: User;
  year: string;
  showError: (errorTitle: string, errorMessage: string) => void;
  onRequestLogout: () => void;
};

type UserStatsTableState = {
  userStats: UserMonthStats[];
  loadingState: 'loading' | 'loaded' | 'error';
};

const initialState: UserStatsTableState = {
  userStats: [],
  loadingState: 'loading',
};

export default class UserStatsTable extends React.Component<
  UserStatsTableProps,
  UserStatsTableState
> {
  state: UserStatsTableState = initialState;

  componentDidMount(): void {
    this.handleLoadUserStats();
  }

  componentDidUpdate(prevProps: UserStatsTableProps): void {
    if (prevProps.year !== this.props.year) {
      this.handleLoadUserStats();
    }
  }

  setLoading = (): void => {
    this.setState({ loadingState: 'loading' });
  };

  setError = (): void => {
    this.setState({ loadingState: 'error' });
  };

  handleLoadUserStats = async (): Promise<void> => {
    try {
      this.setLoading();
      const response = await fetchUserStats(
        this.props.user.accessToken,
        this.props.year,
      );
      this.setState({
        userStats: response.stats,
        loadingState: 'loaded',
      });
    } catch (error) {
      this.setError();
      if (
        error.message === 'Network Error' ||
        error.message === 'Internal Server Error'
      ) {
        this.props.showError(
          'Error de conexión',
          'Ocurrió un error de conexión. Intenta más tarde.',
        );
      } else if (error.message === 'Forbidden') {
        this.props.showError(
          'Sesion expirada',
          'Tu sesión expiró. Por favor ingresa nuevamente.',
        );
        this.props.onRequestLogout();
      } else {
        this.props.showError(
          'Error desconocido',
          'Ocurrió un error. Intenta más tarde.',
        );
      }
    }
  };

  render(): JSX.Element {
    const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
    return (
      <UserStatsTableContainer>
        {this.state.loadingState === 'loading' ? (
          <Loading>
            <Spin indicator={antIcon} />
          </Loading>
        ) : null}

        {this.state.loadingState === 'error' &&
        this.state.userStats.length === 0
          ? 'No se pudo cargar datos.'
          : null}

        {this.state.loadingState === 'loaded' &&
        this.state.userStats.length === 0
          ? 'No se encontraron datos.'
          : null}

        {this.state.userStats.length > 0 ? (
          <>
            <UserStatsTableHeader>
              <UserStatsTableHeaderItem width="30%">
                Mes
              </UserStatsTableHeaderItem>
              <UserStatsTableHeaderItem width="30%">
                <NumberHeader>Ingresos totales</NumberHeader>
              </UserStatsTableHeaderItem>
              <UserStatsTableHeaderItem width="30%">
                <NumberHeader>Usuarios activos</NumberHeader>
              </UserStatsTableHeaderItem>
              <UserStatsTableHeaderItem width="30%">
                <NumberHeader>Usuarios nuevos</NumberHeader>
              </UserStatsTableHeaderItem>
            </UserStatsTableHeader>
            {this.state.userStats.map(
              ({
                month,
                totalAppLoads,
                uniqueUsers,
                newUsers,
              }: UserMonthStats): JSX.Element => {
                return (
                  <UserStatsTableItem
                    key={month}
                    month={month}
                    totalAppLoads={totalAppLoads}
                    uniqueUsers={uniqueUsers}
                    newUsers={newUsers}
                  />
                );
              },
            )}
          </>
        ) : null}
      </UserStatsTableContainer>
    );
  }
}
