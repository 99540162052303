import React from 'react';
import styled from '@emotion/styled';
import { Pagination, Select } from 'antd';

const StyledPagination = styled.div({
  paddingTop: 10,
  '@media(min-width: 420px)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  // antd overrides
  '.ant-pagination-item-active:focus a, .ant-pagination-item-active:hover a': {
    color: '#8FBE00',
  },
  '.ant-pagination-item:focus a, .ant-pagination-item:hover a': {
    color: '#8FBE00',
  },
  '.ant-pagination-item-active a': {
    color: '#8FBE00',
  },
  '.ant-pagination-item-active': {
    borderColor: '#8FBE00',
  },
  '.ant-pagination-item:focus, .ant-pagination-item:hover': {
    borderColor: '#8FBE00',
  },
  '.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon': {
    color: '#8FBE00',
  },
  '.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link': {
    color: '#8FBE00',
    borderColor: '#8FBE00',
  },
});

const StyledPageSize = styled.div({
  paddingLeft: 16,
  // antd overrides
  '.ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
    borderColor: '#8FBE00',
  },
  '.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector': {
    boxShadow: 'none',
    borderColor: '#8FBE00',
  },
});

type CustomPaginationProps = {
  page: number;
  pageSize: number;
  totalItems: number;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
};

const CustomPagination = ({
  page,
  pageSize,
  totalItems,
  handlePageChange,
  handlePageSizeChange,
}: CustomPaginationProps) => {
  return (
    <StyledPagination>
      <Pagination
        current={page}
        onChange={handlePageChange}
        pageSize={pageSize}
        total={totalItems}
        showSizeChanger={false}
      />
      <StyledPageSize>
        <Select defaultValue={pageSize} onChange={handlePageSizeChange}>
          <Select.Option value={10}>10 por página</Select.Option>
          <Select.Option value={20}>20 por página</Select.Option>
          <Select.Option value={50}>50 por página</Select.Option>
          <Select.Option value={100}>100 por página</Select.Option>
        </Select>
      </StyledPageSize>
    </StyledPagination>
  );
};

export default CustomPagination;
