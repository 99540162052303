import React from 'react';
import styled from '@emotion/styled';

const StyledButton = styled.button({
  backgroundColor: 'transparent',
  padding: '4px 21px',
  border: '1px solid #ffffff',
  borderRadius: 14,
  fontFamily: 'Helvetica Neue',
  fontSize: 15,
  lineHeight: 1.2,
  color: '#ffffff',
  minWidth: 100,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

type ButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit';
  children: React.ReactNode;
};

export default function HeaderButton({
  onClick,
  disabled,
  type,
  children,
}: ButtonProps): JSX.Element {
  return (
    <StyledButton type={type} onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
}
