import React from 'react';

type RejectSVGProps = {
  color?: string;
  width?: number;
  height?: number;
};

const RejectSVG = ({
  color = '#8fbe00',
  width = 18,
  height = 18,
}: RejectSVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 68.729 68.729"
    >
      <path
        fill={color}
        d="M34.364,0A34.364,34.364,0,1,0,68.729,34.364,34.364,34.364,0,0,0,34.364,0Zm0,64.433A30.069,30.069,0,1,1,64.433,34.364,30.069,30.069,0,0,1,34.364,64.433Zm0,0"
      />
      <path
        fill={color}
        d="M160.734,127.323a2.148,2.148,0,0,0-3.037,0l-13.668,13.668L130.36,127.323a2.148,2.148,0,0,0-3.09,2.984l.052.053,13.668,13.668L127.323,157.7a2.148,2.148,0,0,0,2.984,3.09l.053-.052,13.668-13.668L157.7,160.734a2.148,2.148,0,0,0,3.037-3.037l-13.668-13.668,13.668-13.668A2.148,2.148,0,0,0,160.734,127.323Zm0,0"
        transform="translate(-109.664 -109.664)"
      />
    </svg>
  );
};

export default RejectSVG;
