import React from 'react';
import styled from '@emotion/styled';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.25)',
});

const CustomLoading = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
  return (
    <Loading>
      <Spin indicator={antIcon} />
    </Loading>
  );
};

export default CustomLoading;
