import React from 'react';
import styled from '@emotion/styled';
import CustomActionButton from './CustomActionButton';

type TableHeaderItemProps = {
  width?: string;
  onClick?: () => void;
};

type ActionButtonsItemProps = {
  width?: string | number;
};

const TableHeader = styled.div({
  display: 'none',
  padding: '4px 0',
  borderRadius: '20px',
  borderBottom: '1px solid #F5F5F5',
  fontFamily: 'Helvetica Neue',
  fontSize: 14,
  lineHeight: 20 / 14,
  color: '#464646',
  backgroundColor: '#F0F0F0',
  '@media(min-width: 800px)': {
    display: 'flex',
  },
});

const TableHeaderItem = styled('div')<TableHeaderItemProps>(
  {},
  ({ width = '100%', onClick }) => {
    const canClick = Boolean(onClick);
    return {
      cursor: canClick ? 'pointer' : 'default',
      width: '100%',
      flexGrow: 1,
      padding: '5px 29px',
      color: '#505050',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '@media(min-width: 800px)': {
        width,
      },
    };
  },
);

const ActionButtonsItem = styled('div')<ActionButtonsItemProps>(
  {
    flexShrink: 0,
    padding: '5px 17px',
    '@media(min-width: 800px)': {
      padding: '0px 15px 0px 0px',
      margin: '-8px -7px -8px 0',
    },
  },
  ({ width = '100%' }) => {
    return {
      width: '100%',
      '@media(min-width: 800px)': {
        width,
      },
    };
  },
);

type Column = {
  dataField: string;
  text: string;
  onPressHeader?: () => void;
  width: string;
};

type ActionButton = {
  tooltip: string;
  actionButton: () => void;
  icon: JSX.Element;
};

type CustomTableProps = {
  columns: Column[];
  data: any[];
};

const TableRow = styled.div({
  padding: '10px 0',
  borderTop: '1px solid #F5F5F5',
  fontFamily: 'Helvetica Neue Bold',
  fontSize: 16,
  lineHeight: 19 / 16,
  color: '#464646',
  '@media(min-width: 800px)': {
    display: 'flex',
  },
});

type TableColumnItemProps = {
  width?: string | number;
};

const TableColumnItem = styled('div')<TableColumnItemProps>(
  {
    flexGrow: 1,
    padding: '5px 29px',
    color: '#505050',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Helvetica Neue',
    fontSize: '16px',
  },
  (props) => {
    const width = props.width || '100%';
    return {
      width: '100%',
      '@media(min-width: 800px)': {
        width,
      },
    };
  },
);

const CustomTable = ({ columns, data }: CustomTableProps) => {
  return (
    <>
      <TableHeader>
        {columns.map((column, i) => (
          <TableHeaderItem
            key={`header_${i}`}
            width={column.width}
            onClick={column.onPressHeader}
          >
            {column.text}
          </TableHeaderItem>
        ))}
      </TableHeader>

      {data.map((row, i) => {
        return (
          <TableRow
            key={`row_${i}`}
            style={i === 0 ? { borderTop: '0px' } : {}}
          >
            {columns.map((column) => {
              const info = row[column.dataField];
              if (!info) {
                return (
                  <div
                    key={`${column.dataField}_${i}`}
                    style={{ width: column.width }}
                  ></div>
                );
              } else if (info.format) {
                if (info.format === 'actions') {
                  return (
                    <ActionButtonsItem
                      width="auto"
                      key={`${column.dataField}_${i}`}
                    >
                      {info.value.map((button: ActionButton, j: number) => (
                        <CustomActionButton
                          key={`${column.dataField}_${i}_${j}`}
                          onPressButton={button.actionButton}
                          tooltip={button.tooltip}
                          icon={button.icon}
                        />
                      ))}
                    </ActionButtonsItem>
                  );
                }
                return <div></div>;
              } else {
                return (
                  <TableColumnItem
                    key={`${column.dataField}_${i}`}
                    width={column.width}
                    style={info.style || {}}
                  >
                    {info.value || null}
                  </TableColumnItem>
                );
              }
            })}
          </TableRow>
        );
      })}
    </>
  );
};

export default CustomTable;
