import React from 'react';
import styled from '@emotion/styled';

function formatMonth(month: string): string {
  const monthsMap: { [number: string]: string } = {
    '1': 'Enero',
    '2': 'Febrero',
    '3': 'Marzo',
    '4': 'Abril',
    '5': 'Mayo',
    '6': 'Junio',
    '7': 'Julio',
    '8': 'Agosto',
    '9': 'Septiembre',
    '10': 'Octubre',
    '11': 'Noviembre',
    '12': 'Diciembre',
  };
  return monthsMap[month];
}

const StyledUserStatsTableItem = styled.div({
  display: 'flex',
  padding: '10px 0',
  borderTop: '1px solid #F5F5F5',
  fontFamily: 'Helvetica Neue Bold',
  fontSize: 16,
  lineHeight: 19 / 16,
  color: '#464646',
});

const Number = styled.div({
  fontFamily: 'Helvetica Neue',
  textAlign: 'right',
  color: '#F19334',
});

type UserStatsTableItemFieldProps = {
  width?: string | number;
};

const UserStatsTableItemField = styled('div')<UserStatsTableItemFieldProps>(
  {
    flexGrow: 1,
    padding: '5px 29px',
    color: '#505050',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  (props) => {
    const width = props.width || '100%';
    return {
      width: '100%',
      '@media(min-width: 800px)': {
        width,
      },
    };
  },
);

type UserStatsTableItemProps = {
  month: string;
  totalAppLoads: number;
  uniqueUsers: number;
  newUsers: number;
};

export default class UserStatsTableItem extends React.Component<
  UserStatsTableItemProps
> {
  render(): JSX.Element {
    const { month, totalAppLoads, uniqueUsers, newUsers } = this.props;

    return (
      <StyledUserStatsTableItem key={month}>
        <UserStatsTableItemField width="30%">
          {formatMonth(month)}
        </UserStatsTableItemField>
        <UserStatsTableItemField width="30%">
          <Number>{totalAppLoads}</Number>
        </UserStatsTableItemField>
        <UserStatsTableItemField width="30%">
          <Number>{uniqueUsers}</Number>
        </UserStatsTableItemField>
        <UserStatsTableItemField width="30%">
          <Number>{newUsers}</Number>
        </UserStatsTableItemField>
      </StyledUserStatsTableItem>
    );
  }
}
