import React from 'react';
import styled from '@emotion/styled';
import { History } from 'history';

import { AppContext } from '../context/app-context';
import BulkCreateUsers from './BulkCreateUsers';
import Button from './Button';
import Header from './Header';
import Modal from './Modal';
import UserList from './UserList';
import UserSearchBox from './UserSearchBox';
import ExportUsersButton from './ExportUsersButton';
import { ReactComponent as SvgUpload } from '../svg/upload-small.svg';

const Background = styled.div({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#F5F5F5',
  paddingBottom: 20,
});

const Container = styled.div({
  width: 'calc(100% - 40px)',
  maxWidth: '1290px',
  backgroundColor: '#ffffff',
  padding: '20px 20px 40px',
  boxShadow: '0px 2px 6px rgb(0, 0, 0, 0.11)',
  '@media(min-width: 420px)': {
    width: 'calc(100% - 80px)',
  },
});

const ContainerHeader = styled.div({
  paddingBottom: 10,
  '@media(min-width: 800px)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const SearchBoxPadding = styled.div({
  paddingBottom: 10,
  '@media(min-width: 800px)': {
    flexGrow: 1,
    paddingBottom: 0,
    paddingRight: 10,
  },
});

const ButtonPadding = styled.div({
  padding: '5px 0px',
  '@media(min-width: 800px)': {
    padding: '0 5px',
  },
});

type User = {
  username: string;
  accessToken: string;
};

type HomeProps = {
  user: User;
  showError: (errorTitle: string, errorMessage: string) => void;
  logout: () => void;
  history: History;
};

type HomeState = {
  usersRefreshRequested: boolean;
  isBulkCreateUsersOpen: boolean;
  userSearchString: string;
};

export default class Home extends React.Component<HomeProps, HomeState> {
  state = {
    usersRefreshRequested: false,
    isBulkCreateUsersOpen: false,
    userSearchString: '',
  };

  requestRefreshUsers = (): void => {
    this.setState({ usersRefreshRequested: true });
  };

  resetRequestRefreshUsers = (): void => {
    this.setState({ usersRefreshRequested: false });
  };

  handleUserSearchStringChange = (searchString: string): void => {
    this.setState({ userSearchString: searchString });
  };

  redirectToEditUser = (id: number): void => {
    this.props.history.push(`/users/${id}/edit`);
  };

  render(): JSX.Element {
    return (
      <Background>
        <Header />
        <Container>
          <ContainerHeader>
            {/* Search users */}
            <SearchBoxPadding>
              <UserSearchBox
                onRequestSearch={this.handleUserSearchStringChange}
              />
            </SearchBoxPadding>

            {/* Export users */}
            <ButtonPadding>
              <AppContext.Consumer>
                {({ user, showError, logout }): JSX.Element => {
                  return (
                    <>
                      {user ? (
                        <ExportUsersButton
                          user={user}
                          showError={showError}
                          onRequestLogout={logout}
                        />
                      ) : null}
                    </>
                  );
                }}
              </AppContext.Consumer>
            </ButtonPadding>

            {/* Bulk create users */}
            <ButtonPadding>
              <Button
                onClick={(): void => {
                  this.setState({ isBulkCreateUsersOpen: true });
                }}
                icon={<SvgUpload width="17" />}
                size="small"
              >
                Carga masiva de usuarios
              </Button>
            </ButtonPadding>
          </ContainerHeader>
          <UserList
            onRequestLogout={this.props.logout}
            user={this.props.user}
            showError={this.props.showError}
            refreshRequested={this.state.usersRefreshRequested}
            resetRefreshRequested={this.resetRequestRefreshUsers}
            search={this.state.userSearchString}
            redirectToEditUser={this.redirectToEditUser}
          />
        </Container>
        {/* Batch load users */}
        <Modal
          isOpen={this.state.isBulkCreateUsersOpen}
          onRequestClose={(): void => {
            // TODO: fix the modal it doesn't close when clicking
            // anywhere
            //this.setState({ isBulkCreateUsersOpen: false });
          }}
        >
          <BulkCreateUsers
            user={this.props.user}
            onRequestClose={(): void => {
              this.setState({ isBulkCreateUsersOpen: false });
            }}
            onRequestLogout={this.props.logout}
            onLoad={(): void => {
              this.requestRefreshUsers();
            }}
            showError={this.props.showError}
          />
        </Modal>
      </Background>
    );
  }
}
