import React from 'react';

type User = {
  username: string;
  accessToken: string;
};

type Context = {
  user: User | null;
  logout: () => void;
  showError: (errorTitle: string, errorMessage: string) => void;
};

export const AppContext = React.createContext<Context>({
  user: null,
  logout: () => {
    console.log('logout');
  },
  showError: () => {
    console.log('showError');
  },
});
