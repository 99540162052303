// // TODO: dedup
// type User = { email: string; name: string; internal: boolean; token: string };
// type Retailer = { domain: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function storeData(key: string, value: any): any {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeData(key: string): any {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.log(error);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function readData(key: string): any {
  try {
    const itemString = localStorage.getItem(key);
    return itemString !== null ? JSON.parse(itemString) : null;
  } catch (error) {
    console.log(error);
  }
}
