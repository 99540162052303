import React from 'react';
import styled from '@emotion/styled';
import { History } from 'history';

import Header from './Header';
import UserStatsTable from './UserStatsTable';
import UserStatsYearSelect from './UserStatsYearSelect';

const Background = styled.div({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#F5F5F5',
  paddingBottom: 20,
});

const Container = styled.div({
  width: 'calc(100% - 40px)',
  maxWidth: '795px',
  backgroundColor: '#ffffff',
  padding: '20px 20px 40px',
  boxShadow: '0px 2px 6px rgb(0, 0, 0, 0.11)',
  '@media(min-width: 420px)': {
    width: 'calc(100% - 80px)',
  },
});

const ContainerHeader = styled.div({
  paddingBottom: 10,
  '@media(min-width: 800px)': {
    display: 'flex',
    justifyContent: 'flex-start',
  },
});

const YearSelectPadding = styled.div({
  paddingBottom: 10,
  '@media(min-width: 800px)': {
    paddingBottom: 0,
    paddingRight: 10,
  },
});

type User = {
  username: string;
  accessToken: string;
};

type UserStatsProps = {
  user: User;
  showError: (errorTitle: string, errorMessage: string) => void;
  logout: () => void;
  history: History;
};

type UserStatsState = {
  year: string;
};

export default class UserStats extends React.Component<
  UserStatsProps,
  UserStatsState
> {
  state = {
    year: '2020',
  };

  handleYearChange = (year: string): void => {
    this.setState({ year });
  };

  render(): JSX.Element {
    return (
      <Background>
        <Header />
        <Container>
          <ContainerHeader>
            <YearSelectPadding>
              <UserStatsYearSelect
                value={this.state.year}
                onChange={this.handleYearChange}
              />
            </YearSelectPadding>
          </ContainerHeader>
          <UserStatsTable
            onRequestLogout={this.props.logout}
            user={this.props.user}
            showError={this.props.showError}
            year={this.state.year}
          />
        </Container>
      </Background>
    );
  }
}
