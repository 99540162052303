import React from 'react';
import { readData } from '../utils/storage';
import { Redirect } from 'react-router-dom';

type User = {
  username: string;
  accessToken: string;
};

type Props = {
  render: (user: User) => JSX.Element;
};

const AuthGuard = (props: Props): JSX.Element => {
  const user = readData('user') as User | null;
  // TODO: Check that the JWT is still valid before rendering the page.
  // It's a minor issue right now, since every page inmediately
  // requests the API and if the token is invalid the user is
  // signout automatically.
  return !user ? <Redirect to="/login" /> : props.render(user);
};

export default AuthGuard;
