import React from 'react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';

const ActionButton = styled.button({
  width: 32,
  border: 'none',
  padding: '12px 7px',
  cursor: 'pointer',
  background: 'none',
});

type CustomActionButtonProps = {
  onPressButton: () => void;
  tooltip: string;
  icon: JSX.Element;
};

const CustomActionButton = ({
  onPressButton,
  tooltip,
  icon,
}: CustomActionButtonProps) => {
  return (
    <ActionButton onClick={onPressButton}>
      <Tooltip
        title={tooltip}
        arrowPointAtCenter
        placement="bottomRight"
        // Error in tooltip typescript definition throws compilation
        // errors when passing a custom color
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        color="#8EC000"
      >
        <div>{icon}</div>
      </Tooltip>
    </ActionButton>
  );
};

export default CustomActionButton;
