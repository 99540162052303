import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { storeData, removeData, readData } from '../utils/storage';

import { AppContext } from '../context/app-context';
import ErrorModal from './ErrorModal';
import Home from './Home';
import UserStats from './UserStats';
import Login from './Login';

import './App.css';
import AuthGuard from './AuthGuard';
import EditUser from './EditUser';
import Comments from './Comments';

type User = {
  username: string;
  accessToken: string;
};

type AppState = {
  user: User | null;
  errorTitle: string;
  errorMessage: string;
};

class App extends React.Component<{}, AppState> {
  state: AppState = { user: null, errorTitle: '', errorMessage: '' };

  componentDidMount(): void {
    // Initialize user
    const user = readData('user') as User | null;
    this.setState({ user });
  }

  setUser = (user: User): void => {
    storeData('user', user);
    this.setState({ user });
  };

  logout = (): void => {
    removeData('user');
    this.setState({ user: null });
  };

  showError = (errorTitle: string, errorMessage: string): void => {
    this.setState({ errorTitle, errorMessage });
  };

  clearError = (): void => {
    this.setState({ errorTitle: '', errorMessage: '' });
  };

  handleErrors = (error: any): void => {
    if (error.message === 'Unauthorized') {
      this.showError(
        '¡Credenciales inválidas!',
        'Tus credenciales no son correctas. Inténtalo de nuevo.',
      );
    } else if (
      error.message === 'Network Error' ||
      error.message === 'Internal Server Error'
    ) {
      this.showError(
        'Error de conexión',
        'Ocurrió un error de conexión. Intenta más tarde.',
      );
    } else if (error.message === 'Forbidden') {
      this.showError(
        'Sesion expirada',
        'Tu sesión expiró. Por favor ingresa nuevamente.',
      );
      this.logout();
    } else {
      this.showError(
        'Error desconocido',
        'Ocurrió un error. Intenta más tarde.',
      );
    }
  };

  render(): JSX.Element {
    return (
      <div>
        <AppContext.Provider
          value={{
            user: this.state.user,
            logout: this.logout,
            showError: this.showError,
          }}
        >
          <Switch>
            <Route
              exact
              path="/login"
              render={(props): JSX.Element => {
                if (!this.state.user) {
                  return (
                    <Login
                      setUser={this.setUser}
                      showError={this.showError}
                      {...props}
                    />
                  );
                }
                return <Redirect from="/login" to="/users" />;
              }}
            />
            <Route
              exact
              path="/users"
              render={(props): JSX.Element => (
                <AuthGuard
                  render={(user): JSX.Element => (
                    <Home
                      logout={this.logout}
                      user={user}
                      showError={this.showError}
                      {...props}
                    />
                  )}
                />
              )}
            />
            <Route
              exact
              path="/users/:id/edit"
              render={(props): JSX.Element => (
                <AuthGuard
                  render={(user): JSX.Element => (
                    <EditUser
                      user={user}
                      handleErrors={this.handleErrors}
                      {...props}
                    />
                  )}
                />
              )}
            />
            <Route
              exact
              path="/user-activity"
              render={(props): JSX.Element => (
                <AuthGuard
                  render={(user): JSX.Element => (
                    <UserStats
                      logout={this.logout}
                      user={user}
                      showError={this.showError}
                      {...props}
                    />
                  )}
                />
              )}
            />
            <Route
              exact
              path="/comments"
              render={(props): JSX.Element => (
                <AuthGuard
                  render={(user): JSX.Element => (
                    <Comments
                      logout={this.logout}
                      user={user}
                      showError={this.showError}
                      {...props}
                    />
                  )}
                />
              )}
            />
            <Route path="/">
              <Redirect from="/" to="/login" />
            </Route>
          </Switch>
        </AppContext.Provider>

        <ErrorModal
          isOpen={this.state.errorTitle !== ''}
          errorTitle={this.state.errorTitle}
          errorMessage={this.state.errorMessage}
          onRequestClose={this.clearError}
        />
      </div>
    );
  }
}

export default App;
