import React from 'react';
import { saveAs } from 'file-saver';

import Button from './Button';
import { ReactComponent as SvgExport } from '../svg/export.svg';
import { requestExportComments } from '../api/request';

type User = {
  username: string;
  accessToken: string;
};

type ExportCommentsButtonProps = {
  user: User;
  onRequestLogout: () => void;
  showError: (errorTitle: string, errorMessage: string) => void;
};

type ExportCommentsButtonState = {
  loading: boolean;
};

export default class ExportCommentsButton extends React.Component<
  ExportCommentsButtonProps,
  ExportCommentsButtonState
> {
  state: ExportCommentsButtonState = {
    loading: false,
  };

  handleExportComments = async (): Promise<void> => {
    try {
      this.setState({ loading: true });
      const res = await requestExportComments(
        this.props.user !== null ? this.props.user.accessToken : '',
      );
      saveAs(res.data, 'Comentarios.csv', { autoBom: true });
      this.setState({ loading: false });
    } catch (error) {
      if (error.response?.status === 403) {
        this.props.showError(
          'Sesión expirada',
          'Tu sesión expiró. Por favor ingresa nuevamente.',
        );
        this.props.onRequestLogout();
      } else {
        if (
          error.response?.status === 500 ||
          error.message === 'Network Error'
        ) {
          this.props.showError(
            'Error de conexión',
            'Ocurrió un error de conexión. Intenta más tarde.',
          );
        } else {
          this.props.showError(
            'Error desconocido',
            'Ocurrió un error. Intenta más tarde.',
          );
        }
        this.setState({ loading: false });
      }
    }
  };

  render(): JSX.Element {
    return (
      <Button
        onClick={this.handleExportComments}
        icon={<SvgExport />}
        size="small"
        variant="orange"
      >
        {this.state.loading ? 'Exportando...' : 'Exportar comentarios'}
      </Button>
    );
  }
}
