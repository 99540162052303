import React from 'react';
import ReactModal from 'react-modal';
import styled from '@emotion/styled';

const Container = styled.div({
  padding: 50,
});

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .26)',
  },
  content: {
    top: '60px',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, 0%)',
    borderRadius: 8,
    boxShadow: '0px 6px 10px #00000034',
    padding: 0,
  },
};

type ModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  children: React.ReactNode;
};

export default function Modal({
  isOpen,
  onRequestClose,
  children,
}: ModalProps): JSX.Element {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      {/*  TODO: make close on container click optional      */}
      <Container onClick={onRequestClose}>{children}</Container>
    </ReactModal>
  );
}
