import axios from 'axios';

const baseUrl = process.env.REACT_APP_API_URL;

const API = axios.create({
  baseURL: baseUrl,
});

export const requestCommentList = async (
  accessToken: string,
  params: {
    offset: number;
    limit: number;
    sort:
      | 'userName'
      | 'userRut'
      | 'text'
      | 'createdAt'
      | 'contentTitle'
      | 'status';
    order: 'ASC' | 'DESC';
  },
) => {
  return await API.get('/admin/comments', {
    headers: { Authorization: 'Bearer ' + accessToken },
    params,
  });
};

export const patchCommentStatus = async (
  accessToken: string,
  commentId: number,
  status: string,
) => {
  return await API.patch(
    `/admin/comments/${commentId}`,
    { status },
    {
      headers: { Authorization: 'Bearer ' + accessToken },
    },
  );
};

export const resetPassword = async (rut: string) => {
  return await API.post(`/password-reset`, { rut, isActivation: false });
};

export const requestExportComments = async (accessToken: string) => {
  return await API.get('/admin/comments/csv', {
    responseType: 'blob',
    headers: { Authorization: 'Bearer ' + accessToken },
  });
};
