import React from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { RouteComponentProps } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, FieldProps } from 'formik';
import { Select, Radio, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { format, formatISO } from 'date-fns';

import Header from './Header';
import Button from './Button';
import Modal from './Modal';
import SimpleModal from './SimpleModal';

const { Option } = Select;

const COLORS = {
  ERROR: 'red',
  DISABLED: '#919797',
  INPUT_TEXT: '#464646',
  GREEN: '#8EC000',
  DARK_GREEN: '#6E9500',
};

const Background = styled.div({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#F5F5F5',
  paddingBottom: 20,
});

const Loading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(255, 255, 255, 0.25)',
});

const Container = styled.div({
  width: 'calc(100% - 40px)',
  maxWidth: '1290px',
  backgroundColor: '#ffffff',
  padding: '20px 20px 40px',
  boxShadow: '0px 2px 6px rgb(0, 0, 0, 0.11)',
  '@media(min-width: 420px)': {
    width: 'calc(100% - 80px)',
  },
});

// Form styles
const EditUserForm = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: 5,
});
const EditUserControlGroup = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  '@media(max-width: 900px)': {
    flexDirection: 'column',
  },
});
const FormActionButtonSpacer = styled.div({
  width: '100%',
  height: '25px',
});
const EditUserControl = styled.div({
  flex: '1 1 0',
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  margin: '10px 10px 0px',
});
const ErrorMessageContainer = styled.div({
  padding: 10,
  borderWidth: 1,
  borderColor: '#8EC000',
  borderRadius: 26,
  fontWeight: 600,
  fontSize: 10,
  lineHeight: 1.2,
  color: '#c42525',
  height: '10px',
});
const InputWrapper = styled.div((props: any) => ({
  display: 'flex',
  flexDirection: props.flexDirection ? props.flexDirection : 'column',
  alignItems: props.alignItems ? props.alignItems : 'stretch',
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: props.hasError
    ? COLORS.ERROR
    : props.disabled
    ? COLORS.DISABLED
    : COLORS.GREEN,
  borderRadius: 28,
  padding: '0 20px',
  ':focus-within': {
    borderColor: COLORS.DARK_GREEN,
  },
  ':disabled': {
    borderColor: COLORS.DISABLED,
  },
  '.ant-select': {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 600,
    color: COLORS.INPUT_TEXT,
  },
  '.ant-select-selector': {
    borderColor: COLORS.GREEN,
  },
  '.anticon svg': {
    fill: COLORS.GREEN,
  },
  '.ant-select-single:not(.ant-select-cuztomize-input) .ant-select-selector': {
    border: 'none',
  },
  '.ant-select:not(.ant-select-disabled):hover .ant-select-selector': {
    borderColor: COLORS.DARK_GREEN,
  },
}));
const InputTitle = styled.div({
  width: 'auto',
  height: '16px',
  marginBottom: '10px',
  paddingLeft: '15px',
  fontSize: '14px',
  color: '#505050',
});
const StyledInput = styled.input({
  flexGrow: 1,
  height: '16px',
  padding: '20px 30px 20px 10px',
  lineHeight: 19 / 16,
  color: COLORS.INPUT_TEXT,
  fontWeight: 600,
  border: 'none',
  backgroundColor: 'bone',
  ':focus': {
    outline: 'none',
  },
  ':disabled': {
    color: '#919797',
    backgroundColor: 'transparent',
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function TextInput(props: any): JSX.Element {
  const hasError = !!props.form.errors[props.field.name];
  return (
    <InputWrapper id="input-wrapper" {...props} hasError={hasError}>
      <StyledInput {...props} {...props.field} />
    </InputWrapper>
  );
}

const TelephoneContryCode = styled.div({
  padding: '9px 0px',
  width: '35px',
  content: '+56',
  display: 'flex',
  alignItems: 'center',
  color: COLORS.INPUT_TEXT,
  fontWeight: 600,
  borderRight: `1px solid ${COLORS.GREEN}`,
});

function TelephoneInput(props: any): JSX.Element {
  const hasError = !!props.form.errors[props.field.name];
  return (
    <InputWrapper
      id="input-wrapper"
      {...props}
      hasError={hasError}
      flexDirection="row"
      alignItems="center"
    >
      <TelephoneContryCode>+56</TelephoneContryCode>
      <StyledInput {...props} {...props.field} />
    </InputWrapper>
  );
}

function SelectInput({
  formProps,
  options,
  setFieldValue,
  setFieldTouched,
}: {
  formProps: FieldProps;
  options: any[];
  setFieldValue: Function;
  setFieldTouched: Function;
}): JSX.Element {
  let selectOptions = [];
  switch (formProps.field.name) {
    case 'levelOneId': {
      selectOptions = options.filter((o) => o.level === '1');
      break;
    }
    case 'levelTwoId': {
      selectOptions = options.filter((o) => o.level === '2');
      break;
    }
    case 'levelThreeId': {
      selectOptions = options.filter((o) => o.level === '3');
      break;
    }
    default: {
      selectOptions = options;
    }
  }
  const hasError = !!formProps.form.errors[formProps.field.name];
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore -> hasError throws ts compilation errors
    <InputWrapper {...formProps} hasError={hasError}>
      <Select
        {...formProps.field}
        value={formProps.field.value}
        onChange={(value) => setFieldValue(formProps.field.name, value)}
        onBlur={() => setFieldTouched(formProps.field.name, true)}
      >
        {selectOptions.map((o) => (
          <Option key={`${formProps.field.name}-${o.id}`} value={o.id}>
            {o.name}
          </Option>
        ))}
      </Select>
    </InputWrapper>
  );
}

const RadioInputContainer = styled.div({
  paddingTop: '5px',
  paddingLeft: '15px',
  '.ant-radio:hover .ant-radio-inner': {
    borderColor: COLORS.GREEN,
  },
  '.ant-radio-checked .ant-radio-inner': {
    borderColor: COLORS.GREEN,
  },
  '.ant-radio-inner::after': {
    backgroundColor: COLORS.GREEN,
  },
});

function RadioInput(props: FieldProps): JSX.Element {
  return (
    <RadioInputContainer>
      <Radio.Group
        onChange={props.field.onChange}
        value={props.field.value}
        name={props.field.name}
      >
        <Radio value={true}>Si</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    </RadioInputContainer>
  );
}

type ApiUserRelatedTable = {
  id: number;
  name: string;
  createdAt: Date;
  updatedAt: Date;
};

type ApiLevels = {
  id: number;
  name: string;
  level: string;
  createdAt: Date;
  updatedAt: Date;
};

type ApiUser = {
  id: number;
  rut: string;
  levelOne: ApiLevels;
  levelTwo: ApiLevels;
  levelThree: ApiLevels;
  jobTitle: ApiUserRelatedTable;
  unit: ApiUserRelatedTable;
  paternalSurname: string;
  maternalSurname: string;
  firstname: string;
  birthdate: string;
  sex: string;
  email: string;
  managerCode: string;
  isManager: boolean;
  subdivision: ApiUserRelatedTable;
  zone: ApiUserRelatedTable;
  createdAt: string;
  updatedAt: string;
  lastLogin: string | null;
  phoneNumber: string;
  phoneExtensionNumber: string;
  cellphoneNumber: string;
  personalEmail: string;
};

type FetchUsersData = {
  users: ApiUser[];
  count: number;
  total: number;
};

type ApiResponseV1<T> = {
  data: T;
};
type ApiResponseV2<T> = {
  data: {
    [key: string]: T;
  };
};

type User = { username: string; accessToken: string };

type MatchParams = {
  id: string;
};

interface EditUserProps extends RouteComponentProps<MatchParams> {
  user: User;
  id?: number; // used when navigatin from the user list
  handleErrors: (error: any) => void;
}

enum LOADING_STATE {
  LOADING,
  LOADED,
}

interface EditUserState {
  apiUser: ApiUser | null;
  loadingState: LOADING_STATE;
  jobTitles: ApiUserRelatedTable[];
  levels: ApiLevels[];
  subdivisions: ApiUserRelatedTable[];
  units: ApiUserRelatedTable[];
  zones: ApiUserRelatedTable[];
  updateSuccess: boolean;
}

export default class EditUser extends React.Component<
  EditUserProps,
  EditUserState
> {
  state: EditUserState = {
    apiUser: null,
    loadingState: LOADING_STATE.LOADING,
    jobTitles: [],
    levels: [],
    subdivisions: [],
    units: [],
    zones: [],
    updateSuccess: false,
  };

  componentDidMount(): void {
    this.onInit();
  }

  onInit = async (): Promise<void> => {
    try {
      this.setState({ loadingState: LOADING_STATE.LOADING });
      const user = await this.fetchUser();
      if (!user) {
        return this.props.history.push('/users');
      }

      const [
        jobTitles,
        levels,
        subdivisions,
        units,
        zones,
      ] = await Promise.all([
        this.fetchApiV2('job-titles'),
        this.fetchApiV1('levels'),
        this.fetchApiV1('subdivisions'),
        this.fetchApiV2('units'),
        this.fetchApiV1('zones'),
      ]);

      this.setState({
        apiUser: user,
        loadingState: LOADING_STATE.LOADED,
        jobTitles,
        levels: (levels as unknown) as ApiLevels[],
        subdivisions,
        units,
        zones,
      });
    } catch (error) {
      this.props.handleErrors(error);
      this.props.history.push('/users');
    }
  };

  fetchUser = async (): Promise<ApiUser | null> => {
    const id = this.props.match.params.id;
    const baseUrl = process.env.REACT_APP_API_URL;
    const res = await axios.request({
      method: 'GET',
      url: `${baseUrl}/admin/users/${id}`,
      headers: { Authorization: 'Bearer ' + this.props.user.accessToken },
    });
    const user: ApiUser = res.data.data;

    if (!user) {
      return null;
    }

    // parse birthdate
    const yyyy = parseInt(user.birthdate.split('-')[0], 10);
    const mm = parseInt(user.birthdate.split('-')[1], 10);
    const dd = parseInt(user.birthdate.split('-')[2], 10);
    user.birthdate = format(new Date(yyyy, mm - 1, dd), 'dd/MM/yyyy');

    // parse telephone
    user.phoneNumber = user.phoneNumber.replace('+56', '');

    return user;
  };

  fetchApiV1 = async (resource: string): Promise<ApiUserRelatedTable[]> => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const url =
      resource === 'levels'
        ? `${baseUrl}/${resource}?level=all`
        : `${baseUrl}/${resource}`;
    const res = await axios.request<ApiResponseV1<ApiUserRelatedTable[]>>({
      method: 'GET',
      url,
      headers: { Authorization: 'Bearer ' + this.props.user.accessToken },
    });
    const data = res.data.data;
    return data;
  };
  fetchApiV2 = async (resource: string): Promise<ApiUserRelatedTable[]> => {
    const baseUrl = process.env.REACT_APP_API_URL;
    const res = await axios.request<ApiResponseV2<ApiUserRelatedTable[]>>({
      method: 'GET',
      url: `${baseUrl}/${resource}`,
      headers: { Authorization: 'Bearer ' + this.props.user.accessToken },
    });
    const key = resource === 'job-titles' ? 'jobTitles' : resource;
    const data = res.data.data[key];
    return data;
  };

  updateUser = async (
    apiUser: ApiUser,
    data: {
      levelOneId: number;
      levelTwoId: number;
      levelThreeId: number;
      jobTitleId: number;
      unitId: number;
      paternalSurname: string;
      maternalSurname: string;
      firstname: string;
      birthdate: string;
      sex: string;
      email: string;
      managerCode: string;
      isManager: boolean;
      subdivisionId: number;
      zoneId: number;
      phoneNumber: string;
    },
  ): Promise<{ success: boolean }> => {
    try {
      const baseUrl = process.env.REACT_APP_API_URL;

      const dd = parseInt(data.birthdate.split('/')[0], 10);
      const mm = parseInt(data.birthdate.split('/')[1], 10);
      const yyyy = parseInt(data.birthdate.split('/')[2], 10);
      const birthdate = formatISO(new Date(yyyy, mm - 1, dd), {
        representation: 'date',
      });

      const res = await axios.request({
        method: 'PUT',
        url: `${baseUrl}/admin/users/${apiUser.id}`,
        headers: { Authorization: 'Bearer ' + this.props.user.accessToken },
        data: {
          levelOneId: data.levelOneId,
          levelTwoId: data.levelTwoId,
          levelThreeId: data.levelThreeId,
          jobTitleId: data.jobTitleId,
          unitId: data.unitId,
          paternalSurname: data.paternalSurname,
          maternalSurname: data.maternalSurname,
          firstname: data.firstname,
          birthdate,
          sex: data.sex,
          email: data.email,
          managerCode: data.managerCode,
          isManager: data.isManager,
          subdivisionId: data.subdivisionId,
          zoneId: data.zoneId,
          phoneNumber: '+56' + data.phoneNumber,
          phoneExtensionNumber: apiUser.phoneExtensionNumber,
          cellphoneNumber: apiUser.cellphoneNumber,
          personalEmail: apiUser.personalEmail,
        },
      });
      return { success: res.status === 200 };
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error('Unauthorized');
      }
      if (error.response?.status === 403) {
        throw new Error('Forbidden');
      }
      if (error.response?.status === 500) {
        throw new Error('Internal Server Error');
      }
      throw error;
    }
  };

  renderForm = (user: ApiUser): JSX.Element => {
    return (
      <Formik
        initialValues={{
          rut: user.rut,
          levelOneId: user.levelOne.id,
          levelTwoId: user.levelTwo.id,
          levelThreeId: user.levelThree.id,
          jobTitleId: user.jobTitle.id,
          unitId: user.unit.id,
          paternalSurname: user.paternalSurname,
          maternalSurname: user.maternalSurname,
          firstname: user.firstname,
          birthdate: user.birthdate,
          sex: user.sex,
          email: user.email,
          managerCode: user.managerCode,
          isManager: user.isManager,
          subdivisionId: user.subdivision.id,
          zoneId: user.zone.id,
          phoneNumber: user.phoneNumber,
        }}
        onSubmit={async (values, { setSubmitting }): Promise<void> => {
          setSubmitting(true);
          await this.updateUser(user, values);
          this.setState({ updateSuccess: true });
        }}
        validationSchema={Yup.object().shape({
          rut: Yup.string().required(),
          levelOneId: Yup.number().required('Debes ingresar el nivel 1'),
          levelTwoId: Yup.number().required('Debes ingresar el nivel 2'),
          levelThreeId: Yup.number().required('Debes ingresar el nivel 3'),
          jobTitleId: Yup.number().required('Debes ingresar el cargo'),
          unitId: Yup.number().required('Debes ingresar la unidad'),
          paternalSurname: Yup.string().required(
            'Debes ingresar el apellido paterno',
          ),
          maternalSurname: Yup.string().required(
            'Debes ingresar el apellido materno',
          ),
          firstname: Yup.string().required('Debes ingresar el o los nombres'),
          birthdate: Yup.string()
            .matches(
              /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g,
              'El formato de la fecha debe ser DD/MM/AAAA',
            )
            .required('Debes ingresar la fecha de nacimiento'),
          sex: Yup.string().required('Debes ingresar el sexo'),
          email: Yup.string().email().required('Debes ingresar el email'),
          managerCode: Yup.string()
            .matches(/^\d+$/, 'El código de jefe deben ser solo números')
            .required('Debes ingresar el código del jefe'),
          isManager: Yup.boolean().required(
            'Debes ingresar si es gerente o no',
          ),
          subdivisionId: Yup.number().required(
            'Debes ingresar la sub-division',
          ),
          zoneId: Yup.number().required('Debes ingresar la zona'),
          phoneNumber: Yup.string(),
        })}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isValid,
          isSubmitting,
          setFieldTouched,
          setFieldValue,
        }): JSX.Element => (
          <Form onSubmit={handleSubmit}>
            <EditUserForm id="edit-user-form">
              <EditUserControlGroup>
                <EditUserControl id="edit-user-control">
                  <InputTitle>Nombres</InputTitle>
                  <Field
                    id="firstname"
                    name="firstname"
                    type="text"
                    onChange={handleChange}
                    value={values.firstname}
                    component={TextInput}
                    placeholder="Nombres"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="firstname" />
                  </ErrorMessageContainer>
                </EditUserControl>
                <EditUserControl id="edit-user-control">
                  <InputTitle>Apellido Paterno</InputTitle>
                  <Field
                    id="paternalSurname"
                    name="paternalSurname"
                    type="text"
                    onChange={handleChange}
                    value={values.paternalSurname}
                    component={TextInput}
                    placeholder="Apellido Paterno"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="paternalSurname" />
                  </ErrorMessageContainer>
                </EditUserControl>
                <EditUserControl id="edit-user-control">
                  <InputTitle>Apellido Materno</InputTitle>
                  <Field
                    id="maternalSurname"
                    name="maternalSurname"
                    type="text"
                    onChange={handleChange}
                    value={values.maternalSurname}
                    component={TextInput}
                    placeholder="Apellido Materno"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="maternalSurname" />
                  </ErrorMessageContainer>
                </EditUserControl>
              </EditUserControlGroup>
              <EditUserControlGroup>
                <EditUserControl id="edit-user-control">
                  <InputTitle>RUT</InputTitle>
                  <Field
                    id="rut"
                    name="rut"
                    type="text"
                    onChange={handleChange}
                    value={values.rut}
                    component={TextInput}
                    placeholder="RUT"
                    disabled
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="rut" />
                  </ErrorMessageContainer>
                </EditUserControl>
                <EditUserControl id="edit-user-control">
                  <InputTitle>Fecha de Nacimiento</InputTitle>
                  <Field
                    id="birthdate"
                    name="birthdate"
                    type="text"
                    onChange={handleChange}
                    value={values.birthdate}
                    component={TextInput}
                    placeholder="DD/MM/AAAA"
                  />
                  <ErrorMessageContainer>
                    <ErrorMessage name="birthdate" />
                  </ErrorMessageContainer>
                </EditUserControl>
              </EditUserControlGroup>
              <EditUserControlGroup>
                <EditUserControl>
                  <InputTitle>Teléfono</InputTitle>
                  <Field
                    id="phoneNumber"
                    name="phoneNumber"
                    type="tel"
                    onChange={handleChange}
                    value={values.phoneNumber}
                    component={TelephoneInput}
                    placeholder="Teléfono (no inlcuir +56)"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="phoneNumber" />
                  </ErrorMessageContainer>
                </EditUserControl>
                <EditUserControl>
                  <InputTitle>Email</InputTitle>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    onChange={handleChange}
                    value={values.email}
                    component={TextInput}
                    placeholder="Email"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="email" />
                  </ErrorMessageContainer>
                </EditUserControl>
              </EditUserControlGroup>
              <EditUserControlGroup>
                <EditUserControl>
                  <InputTitle>Cargo</InputTitle>
                  <Field
                    id="jobTitleId"
                    name="jobTitleId"
                    type="number"
                    onChange={handleChange}
                    value={values.jobTitleId}
                    component={(props: FieldProps): JSX.Element =>
                      SelectInput({
                        formProps: props,
                        options: this.state.jobTitles,
                        setFieldValue,
                        setFieldTouched,
                      })
                    }
                    placeholder="Cargo"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="jobTitleId" />
                  </ErrorMessageContainer>
                </EditUserControl>
                <EditUserControl>
                  <InputTitle>Unidad</InputTitle>
                  <Field
                    id="unitId"
                    name="unitId"
                    type="number"
                    onChange={handleChange}
                    value={values.unitId}
                    component={(props: FieldProps): JSX.Element =>
                      SelectInput({
                        formProps: props,
                        options: this.state.units,
                        setFieldValue,
                        setFieldTouched,
                      })
                    }
                    placeholder="Unidad"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="unitId" />
                  </ErrorMessageContainer>
                </EditUserControl>
              </EditUserControlGroup>
              <EditUserControlGroup>
                <EditUserControl>
                  <InputTitle>Zona</InputTitle>
                  <Field
                    id="zoneId"
                    name="zoneId"
                    type="number"
                    onChange={handleChange}
                    value={values.zoneId}
                    component={(props: FieldProps): JSX.Element =>
                      SelectInput({
                        formProps: props,
                        options: this.state.zones,
                        setFieldValue,
                        setFieldTouched,
                      })
                    }
                    placeholder="Zona"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="zoneId" />
                  </ErrorMessageContainer>
                </EditUserControl>
                <EditUserControl>
                  <InputTitle>Subdivión</InputTitle>
                  <Field
                    id="subdivisionId"
                    name="subdivisionId"
                    type="number"
                    onChange={handleChange}
                    value={values.subdivisionId}
                    component={(props: FieldProps): JSX.Element =>
                      SelectInput({
                        formProps: props,
                        options: this.state.subdivisions,
                        setFieldValue,
                        setFieldTouched,
                      })
                    }
                    placeholder="Subdivisión"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="subdivisionId" />
                  </ErrorMessageContainer>
                </EditUserControl>
              </EditUserControlGroup>
              <EditUserControlGroup>
                <EditUserControl>
                  <InputTitle>Nivel 1</InputTitle>
                  <Field
                    id="levelOneId"
                    name="levelOneId"
                    type="number"
                    onChange={handleChange}
                    value={values.levelOneId}
                    component={(props: FieldProps): JSX.Element =>
                      SelectInput({
                        formProps: props,
                        options: this.state.levels,
                        setFieldValue,
                        setFieldTouched,
                      })
                    }
                    placeholder="Nivel 1"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="levelOneId" />
                  </ErrorMessageContainer>
                </EditUserControl>
                <EditUserControl>
                  <InputTitle>Nivel 2</InputTitle>
                  <Field
                    id="levelTwoId"
                    name="levelTwoId"
                    type="number"
                    onChange={handleChange}
                    value={values.levelTwoId}
                    component={(props: FieldProps): JSX.Element =>
                      SelectInput({
                        formProps: props,
                        options: this.state.levels,
                        setFieldValue,
                        setFieldTouched,
                      })
                    }
                    placeholder="Nivel 2"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="levelTwoId" />
                  </ErrorMessageContainer>
                </EditUserControl>
                <EditUserControl>
                  <InputTitle>Nivel 3</InputTitle>
                  <Field
                    id="levelThreeId"
                    name="levelThreeId"
                    type="number"
                    onChange={handleChange}
                    value={values.levelThreeId}
                    component={(props: FieldProps): JSX.Element =>
                      SelectInput({
                        formProps: props,
                        options: this.state.levels,
                        setFieldValue,
                        setFieldTouched,
                      })
                    }
                    placeholder="Nivel 3"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="levelThreeId" />
                  </ErrorMessageContainer>
                </EditUserControl>
              </EditUserControlGroup>
              <EditUserControlGroup>
                <EditUserControl>
                  <InputTitle>Código jefe</InputTitle>
                  <Field
                    id="managerCode"
                    name="managerCode"
                    type="text"
                    onChange={handleChange}
                    value={values.managerCode}
                    component={TextInput}
                    placeholder="Código jefe"
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="managerCode" />
                  </ErrorMessageContainer>
                </EditUserControl>
                <EditUserControl>
                  <InputTitle>Es jefe</InputTitle>
                  <Field
                    id="isManager"
                    name="isManager"
                    type="radio"
                    onChange={handleChange}
                    value={values.isManager}
                    component={RadioInput}
                  />
                  <ErrorMessageContainer>
                    {' '}
                    <ErrorMessage name="isManager" />
                  </ErrorMessageContainer>
                </EditUserControl>
              </EditUserControlGroup>
              <FormActionButtonSpacer />
              <EditUserControlGroup>
                <EditUserControl>
                  <Button
                    type="button"
                    variant="orange"
                    onClick={() => this.props.history.push('/users')}
                  >
                    Cancelar
                  </Button>
                </EditUserControl>
                <EditUserControl>
                  <Button type="submit" disabled={isSubmitting || !isValid}>
                    {isSubmitting ? 'Guardando cambios' : 'Guardar'}
                  </Button>
                </EditUserControl>
              </EditUserControlGroup>
            </EditUserForm>
          </Form>
        )}
      </Formik>
    );
  };

  render(): JSX.Element {
    const antIcon = <LoadingOutlined style={{ fontSize: 30 }} spin />;
    if (this.state.loadingState === LOADING_STATE.LOADING) {
      return (
        <Loading>
          <Spin indicator={antIcon} />
        </Loading>
      );
    }

    if (
      !this.state.apiUser ||
      !this.state.jobTitles.length ||
      !this.state.levels.length ||
      !this.state.subdivisions.length ||
      !this.state.units.length ||
      !this.state.zones.length
    ) {
      return (
        <Loading>
          <Spin indicator={antIcon} />
        </Loading>
      );
    }

    return (
      <Background>
        <Header title="Edición de usuario" />
        <Container>{this.renderForm(this.state.apiUser)}</Container>
        {this.state.updateSuccess ? (
          <Modal
            isOpen={this.state.updateSuccess}
            onRequestClose={(): void => {
              // TODO: fix the modal so it doesn't close when clicking
              // anywhere
              //this.setState({ isDeleteConfirmationOpen: false });
            }}
          >
            <SimpleModal
              iconName="check"
              title="Actualización exitosa"
              message="Los datos han sido ingresado exitosamente. Seras rederigido al listado de usuarios."
              onRequestClose={(): void => this.props.history.push('/users')}
            />
          </Modal>
        ) : null}
      </Background>
    );
  }
}
