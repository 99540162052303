import React from 'react';
import styled from '@emotion/styled';

import Modal from './Modal';
import { ReactComponent as SvgStop } from '../svg/stop.svg';

const ErrorModalContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#ffffff',
  width: 'calc(100vw - 120px)',
  maxWidth: 345,
});
const ErrorModalLogo = styled.div({
  padding: 12,
});
const ErrorModalTitle = styled.div({
  padding: 12,
  fontSize: 22,
  fontFamily: 'Helvetica Neue Medium',
  lineHeight: 1,
  textAlign: 'center',
  color: '#505050',
});
const ErrorModalMessage = styled.div({
  padding: 12,
  fontSize: 18,
  fontFamily: 'Helvetica Neue Light',
  lineHeight: 1.2,
  textAlign: 'center',
  color: '#9A9797',
});

type ErrorModalProps = {
  isOpen: boolean;
  errorTitle: string;
  errorMessage: string;
  onRequestClose: () => void;
};

export default class ErrorModal extends React.Component<ErrorModalProps> {
  render(): JSX.Element {
    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onRequestClose}
      >
        <ErrorModalContainer>
          <ErrorModalLogo>
            <SvgStop />
          </ErrorModalLogo>
          <ErrorModalTitle>{this.props.errorTitle}</ErrorModalTitle>
          <ErrorModalMessage>{this.props.errorMessage}</ErrorModalMessage>
        </ErrorModalContainer>
      </Modal>
    );
  }
}
