import React from 'react';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';

import { ReactComponent as SvgSearch } from '../svg/search.svg';

const Wrapper = styled.div({
  display: 'flex',
  border: '1px solid #EBEBEB',
  borderRadius: 20,
  '@media(min-width: 800px)': {},
});

const SearchInput = styled.input({
  fontFamily: 'Helvetica Neue',
  fontSize: 16,
  lineHeight: 19 / 16,
  padding: 8,
  border: 'none',
  background: 'none',
  flexGrow: 1,
  '@media(min-width: 800px)': {},
});

const Icon = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '7px 0 7px 14px',
  '@media(min-width: 800px)': {},
});

type UserSearchBoxProps = {
  onRequestSearch: (searchString: string) => void;
};

type UserSearchBoxState = {
  searchString: string;
};

export default class UserSearchBox extends React.Component<
  UserSearchBoxProps,
  UserSearchBoxState
> {
  state = {
    searchString: '',
  };

  handleRequestSearch = debounce((value) => {
    this.props.onRequestSearch(value);
  }, 600);

  handleSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    this.setState({ searchString: value });
    this.handleRequestSearch(value);
  };

  render(): JSX.Element {
    return (
      <Wrapper>
        <Icon>
          <SvgSearch />
        </Icon>
        <SearchInput
          type="text"
          value={this.state.searchString}
          onChange={this.handleSearchStringChange}
        />
      </Wrapper>
    );
  }
}
