import React from 'react';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import styled from '@emotion/styled';
import { History } from 'history';

import Button from './Button';
import MessageModal from './MessageModal';

import headerBackgroundImage from '../svg/fondo_green.svg';
import { ReactComponent as LogoSvg } from '../svg/logo_mutual20.svg';
import { ReactComponent as UserLoginSvg } from '../svg/user-login.svg';
import { ReactComponent as LockSvg } from '../svg/lock.svg';
import { ReactComponent as MailSvg } from '../svg/mail.svg';

// Styles
const LoginContainer = styled.div({});
const Header = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '50px 10px 77px',
  backgroundImage: `url(${headerBackgroundImage})`,
  backgroundPosition: '40% bottom',
  backgroundRepeat: 'none',
  backgroundSize: 'cover',
  height: 277,
});
const Logo = styled.div({});
const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});
const Title = styled.h1({
  margin: 0,
  padding: '10px 0',
  fontFamily: 'Helvetica Neue Bold',
  fontSize: 29,
  lineHeight: 29 / 20,
  color: '#505050',
});
const SubTitle = styled.h2({
  margin: 0,
  padding: '10px 0 28px',
  fontFamily: 'Helvetica Neue Light',
  fontSize: 21,
  lineHeight: 21 / 20,
  textAlign: 'center',
  color: '#505050',
});
const Content = styled.div({
  display: 'flex',
});
const LoginBox = styled.div({
  backgroundColor: '#ffffff',
  flexGrow: 1,
  padding: 5,
  width: 'calc(100vw - 20px)',
  '@media(min-width: 476px)': {
    flexGrow: 0,
    width: 456,
  },
});
const LoginForm = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  padding: 5,
});
const LoginFormControl = styled.div({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
});
const ForgotLink = styled.button({
  padding: '7px 0',
  cursor: 'pointer',
  fontFamily: 'Helvetica Neue Light',
  fontSize: 16,
  lineHeight: 19 / 16,
  color: '#8FBE00',
  background: 'none',
  border: 'none',
  textDecoration: 'underline',
});

// Login request
async function login(
  username: string,
  password: string,
): Promise<{ accessToken: string }> {
  const baseUrl = process.env.REACT_APP_API_URL;
  try {
    const res = await axios({
      method: 'post',
      url: `${baseUrl}/login/portal`,
      data: { username, password },
    });
    return res.data.data;
  } catch (error) {
    if (error.response?.status === 401) {
      throw new Error('Unauthorized');
    }
    if (error.response?.status === 500) {
      throw new Error('Internal Server Error');
    }
    throw error;
  }
}

const InputWrapper = styled.div({
  display: 'flex',
  backgroundColor: '#ffffff',
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: '#8EC000',
  borderRadius: 32,
  padding: '0 20px',
  ':focus-within': {
    borderColor: '#6E9500',
  },
});

const StyledInput = styled.input({
  flexGrow: 1,
  padding: '20px 30px 20px 10px',
  fontFamily: 'Helvetica Neue',
  fontSize: 16,
  lineHeight: 19 / 16,
  color: '#505050',
  minWidth: 100,
  border: 'none',
  background: 'none',
  ':focus': {
    outline: 'none',
  },
});

const InputIcon = styled.div({
  display: 'flex',
  alignItems: 'center',
  padding: '7px 0 7px 14px',
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function UsernameInput(props: any): JSX.Element {
  return (
    <InputWrapper>
      <InputIcon>
        <UserLoginSvg />
      </InputIcon>
      <StyledInput {...props} />
    </InputWrapper>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PasswordInput(props: any): JSX.Element {
  return (
    <InputWrapper>
      <InputIcon>
        <LockSvg />
      </InputIcon>
      <StyledInput {...props} />
    </InputWrapper>
  );
}

const ErrorMessageContainer = styled.div({
  backgroundColor: '#ffffff',
  padding: 10,
  borderWidth: 1,
  borderColor: '#8EC000',
  borderRadius: 26,
  fontFamily: 'Helvetica Neue Medium',
  fontSize: 14,
  lineHeight: 1.2,
  color: '#c42525',
  minWidth: 100,
  minHeight: 37,
});

// Login form component
const loginSchema = Yup.object().shape({
  username: Yup.string().required('Debes ingresar tu usuario'),
  password: Yup.string().required('Debes ingresar tu contraseña'),
});

type LoginProps = {
  history: History;
  setUser: (user: { username: string; accessToken: string }) => void;
  showError: (errorTitle: string, errorMessage: string) => void;
};

type LoginState = {
  errorTitle: string;
  errorMessage: string;
  isForgotPasswordModalOpen: boolean;
};

export default class Login extends React.Component<LoginProps, LoginState> {
  state = {
    errorTitle: '',
    errorMessage: '',
    isForgotPasswordModalOpen: false,
  };

  handleLogin = async (
    values: {
      username: string;
      password: string;
    },
    setSubmitting: (isSubmitting: boolean) => void,
  ): Promise<void> => {
    try {
      const response = await login(values.username, values.password);
      setSubmitting(false);
      this.props.setUser({
        username: values.username,
        accessToken: response.accessToken,
      });
      this.props.history.push('/users');
    } catch (error) {
      setSubmitting(false);
      if (error.message === 'Unauthorized') {
        this.props.showError(
          '¡Credenciales inválidas!',
          'Tus credenciales no son correctas. Inténtalo de nuevo.',
        );
      } else if (
        error.message === 'Network Error' ||
        error.message === 'Internal Server Error'
      ) {
        this.props.showError(
          'Error de conexión',
          'Ocurrió un error de conexión. Intenta más tarde.',
        );
      } else {
        this.props.showError(
          'Error desconocido',
          'Ocurrió un error. Intenta más tarde.',
        );
      }
    }
  };

  render(): JSX.Element {
    return (
      <LoginContainer>
        <Header>
          <Logo>
            <LogoSvg />
          </Logo>
        </Header>
        <ContentWrapper>
          <Title>¡Hola!</Title>
          <SubTitle>Inicia sesión con tus credenciales</SubTitle>
          <Content>
            <LoginBox>
              <Formik
                initialValues={{
                  username: '',
                  password: '',
                }}
                onSubmit={async (values, { setSubmitting }): Promise<void> => {
                  await this.handleLogin(values, setSubmitting);
                }}
                validationSchema={loginSchema}
              >
                {({ values, handleChange, isSubmitting }): JSX.Element => (
                  <Form>
                    <LoginForm>
                      <LoginFormControl>
                        <Field
                          id="username"
                          name="username"
                          type="text"
                          onChange={handleChange}
                          value={values.username}
                          component={UsernameInput}
                          placeholder="Ingresa tu usuario"
                        />
                        <ErrorMessageContainer>
                          {' '}
                          <ErrorMessage name="username" />
                        </ErrorMessageContainer>
                      </LoginFormControl>
                      <LoginFormControl>
                        <Field
                          id="password"
                          name="password"
                          type="password"
                          onChange={handleChange}
                          value={values.password}
                          component={PasswordInput}
                          placeholder="Ingresa tu contraseña"
                        />
                        <ErrorMessageContainer>
                          <ErrorMessage name="password" />
                        </ErrorMessageContainer>
                      </LoginFormControl>
                      <LoginFormControl>
                        <Button type="submit" disabled={isSubmitting}>
                          {isSubmitting ? 'Ingresando...' : 'Ingresar'}
                        </Button>
                      </LoginFormControl>
                    </LoginForm>
                  </Form>
                )}
              </Formik>
            </LoginBox>
          </Content>
          <ForgotLink
            onClick={(): void => {
              this.setState({ isForgotPasswordModalOpen: true });
            }}
          >
            ¿Olvidaste tu contraseña?
          </ForgotLink>
        </ContentWrapper>

        {/* Forgot password modal */}
        <MessageModal
          isOpen={this.state.isForgotPasswordModalOpen}
          title="Recuperación de contraseña"
          message="Favor escribe un correo a comunicacioninterna@mutual.cl para restablecer tu contraseña."
          onRequestClose={(): void => {
            this.setState({ isForgotPasswordModalOpen: false });
          }}
          icon={<MailSvg />}
        />
      </LoginContainer>
    );
  }
}
