import React from 'react';
import styled from '@emotion/styled';
import { History } from 'history';

import Header from './Header';
import CommentList from './CommentList';
import ExportCommentsButton from './ExportCommentsButton';

const Background = styled.div({
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#F5F5F5',
  paddingBottom: 20,
});

const Container = styled.div({
  width: 'calc(100% - 40px)',
  maxWidth: '1290px',
  backgroundColor: '#ffffff',
  padding: '20px 20px 40px',
  boxShadow: '0px 2px 6px rgb(0, 0, 0, 0.11)',
  '@media(min-width: 420px)': {
    width: 'calc(100% - 80px)',
  },
});

const ContainerHeader = styled.div({
  paddingBottom: 10,
  '@media(min-width: 800px)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const ButtonPadding = styled.div({
  padding: '5px 0px',
  '@media(min-width: 800px)': {
    padding: '0 5px',
  },
});

type User = {
  username: string;
  accessToken: string;
};

type CommentsProps = {
  user: User;
  showError: (errorTitle: string, errorMessage: string) => void;
  logout: () => void;
  history: History;
};

type CommentsState = {
  commentsRefreshRequested: boolean;
};

export default class Comments extends React.Component<
  CommentsProps,
  CommentsState
> {
  state = {
    commentsRefreshRequested: false,
  };

  requestRefreshComments = (): void => {
    this.setState({ commentsRefreshRequested: true });
  };

  resetRequestRefreshComments = (): void => {
    this.setState({ commentsRefreshRequested: false });
  };

  render(): JSX.Element {
    const { logout, user, showError } = this.props;
    const { commentsRefreshRequested } = this.state;
    return (
      <Background>
        <Header />
        <Container>
          <ContainerHeader>
            <ButtonPadding>
              {user ? (
                <ExportCommentsButton
                  user={user}
                  showError={showError}
                  onRequestLogout={logout}
                />
              ) : null}
            </ButtonPadding>
          </ContainerHeader>
          <CommentList
            onRequestLogout={logout}
            user={user}
            showError={showError}
            refreshRequested={commentsRefreshRequested}
            resetRefreshRequested={this.resetRequestRefreshComments}
          />
        </Container>
      </Background>
    );
  }
}
