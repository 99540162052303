import React from 'react';
import styled from '@emotion/styled';
import { colors } from '../constants/colors';

const StyledCustomLink = styled('a')({
  color: colors.ORANGE,
  textDecoration: 'underline',
  ':hover': {
    color: colors.ORANGE,
    textDecoration: 'underline',
  },
});

type CustomLinkProps = {
  title: string;
  url: string;
};

const CustomLink = ({ title, url }: CustomLinkProps) => {
  return (
    <StyledCustomLink href={url} target="_blank">
      {title}
    </StyledCustomLink>
  );
};

export default CustomLink;
